/*
 *  Document   : app.js
 *  Author     : pixelcave
 *  Description: UI Framework Custom Functionality (available to all pages)
 *
 */

var App = function() {
    // Helper variables - set in uiInit()
    var $lHtml, $lBody, $lPage, $lSidebar, $lSidebarScroll, $lSideOverlay, $lSideOverlayScroll, $lHeader, $lMain, $lFooter, $lLang;

    /*
     ********************************************************************************************
     *
     * BASE UI FUNCTIONALITY
     *
     * Functions which handle vital UI functionality such as main navigation and layout
     * They are auto initialized in every page
     *
     *********************************************************************************************
     */

    // User Interface init
    var uiInit = function() {
        // Set variables
        $lHtml              = jQuery('html');
        $lBody              = jQuery('body');
        $lPage              = jQuery('#page-container');
        $lSidebar           = jQuery('#sidebar');
        $lSidebarScroll     = jQuery('#sidebar-scroll');
        $lSideOverlay       = jQuery('#side-overlay');
        $lSideOverlayScroll = jQuery('#side-overlay-scroll');
        $lHeader            = jQuery('#header-navbar');
        $lMain              = jQuery('#main-container');
        $lFooter            = jQuery('#page-footer');
        $lLang              = $('html').attr('lang');

        // Initialize Tooltips
        $lBody.tooltip({
            selector: '[data-toggle="tooltip"], .js-popover, .js-tooltip',
            container: 'body',
            animation: false,
            trigger: 'hover'
        });

        // Initialize Popovers
        jQuery('[data-toggle="popover"], .js-popover').popover({
            container: 'body',
            animation: true,
       trigger: 'manual'
    }).click(function(e) {
       $(this).popover('toggle');
       jQuery(".popover input").attr("tabindex",-1).focus();//set the focus on the popover itself
       e.preventDefault();
    });

    $('body').on('blur','.popover',function(){//live event, will delete the popover by clicking any part of the page
       $('[data-toggle="popover"], .js-popover').popover('hide');
    });

    $('.modal').on('show.bs.modal', function () {
        $('[data-toggle="popover"], .js-tooltip').tooltip('hide');
    })

        // Configure AJAX XSRF
        var getXsrfToken = function() {
            var cookies = document.cookie.split(';');
            var token = '';
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i].split('=');
                if(cookie[0] == 'XSRF-TOKEN') {
                    token = decodeURIComponent(cookie[1]);
                }
            }
            return token;
        }

        $.ajaxSetup({
            headers: {
                'X-XSRF-TOKEN': getXsrfToken(),
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        // Global handler for ajax errors
        $( document ).ajaxError(function( event, request, settings ) {
            // Ignore datatable language lookups, which will often 404 if the
            // exact language does not exist.
            if (settings.url.indexOf('datatables/lang') > 0) {
                return false;
            }

            // If we receive a 401 "Unauthorised" error, the user has likely been
            // logged out due to inactivity.
            // Reload the page to push them to the login screen.
            if (request.status == 401) {
                location.reload();
            }
        });

        // Initialize Tabs
        jQuery('[data-toggle="tabs"] a, .js-tabs a').click(function(e){
            e.preventDefault();
            jQuery(this).tab('show');
        });

        // Init form placeholder (for IE9)
        jQuery('.form-control').placeholder();

        // Read data-href tags and turn them into links
        jQuery('[data-href]').click(function (e) {
            if (!$(e.target).hasClass('control')) {
                window.location.href = jQuery(this).data('href');
            }
        })

        // Setup moment calendar strings for 'Today' / 'Tomorrow'
        moment.updateLocale('en', {
            calendar : {
                lastDay : '[Yesterday]',
                sameDay : '[Today]',
                nextDay : '[Tomorrow]',
                lastWeek : '[Last] dddd',
                nextWeek : '[Next] dddd',
                sameElse : 'L'
            }
        });

        // Add relative date class
        $('[data-addrelativedateclass]').each(function () {
            el = $(this);
            var date = moment(el.data('addrelativedateclass'));

            if (date.isSame(new Date(), "day")) {
                el.addClass('today');
            } else if(date.isSame(moment().add(1, 'day'), "day")){
                el.addClass('tomorrow');
            } else if(date.isBefore()){
                el.addClass('past');
            } else if(date.isAfter()){
                el.addClass('future');
            }


        });

        // Convert dates to local time
        $('[data-convertdate]').each(function () {
            el = $(this);
            var dateFormat = el.data('dateformat') ? el.data('dateformat') : 'L LT';

            if (el.data('calendarformat')) {
                el.text(moment(el.data('convertdate')).calendar(null, el.data('calendarformat')));
            } else{
                el.text(moment(el.data('convertdate')).format(dateFormat));
            }
            el.show();
        });
    };

    // Layout functionality
    var uiLayout = function() {
        // Resizes #main-container min height (push footer to the bottom)
        var $resizeTimeout;

        if ($lMain.length) {
            uiHandleMain();

            jQuery(window).on('resize orientationchange', function(){
                clearTimeout($resizeTimeout);

                $resizeTimeout = setTimeout(function(){
                    uiHandleMain();
                }, 150);
            });
        }

        // Init sidebar and side overlay custom scrolling
        uiHandleScroll('init');

        // Init transparent header functionality (solid on scroll - used in frontend)
        if ($lPage.hasClass('header-navbar-fixed') && $lPage.hasClass('header-navbar-transparent')) {
            jQuery(window).on('scroll', function(){
                if (jQuery(this).scrollTop() > 20) {
                    $lPage.addClass('header-navbar-scroll');
                } else {
                    $lPage.removeClass('header-navbar-scroll');
                }
            });
        }

        // Call layout API on button click
        jQuery('[data-toggle="layout"]').on('click', function(){
            var $btn = jQuery(this);

            uiLayoutApi($btn.data('action'));

            if ($lHtml.hasClass('no-focus')) {
                $btn.blur();
            }
        });
    };

    // Resizes #main-container to fill empty space if exists
    var uiHandleMain = function() {
        var $hWindow     = jQuery(window).height();
        var $hHeader     = $lHeader.outerHeight();
        var $hFooter     = $lFooter.outerHeight();

        if ($lPage.hasClass('header-navbar-fixed')) {
            $lMain.css('min-height', $hWindow - $hFooter);
        } else {
            $lMain.css('min-height', $hWindow - ($hHeader + $hFooter));
        }
    };

    // Handles sidebar and side overlay custom scrolling functionality
    var uiHandleScroll = function($mode) {
        var $windowW = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

        // Init scrolling
        if ($mode === 'init') {
            // Init scrolling only if required the first time
            uiHandleScroll();

            // Handle scrolling on resize or orientation change
            var $sScrollTimeout;

            jQuery(window).on('resize orientationchange', function(){
                clearTimeout($sScrollTimeout);

                $sScrollTimeout = setTimeout(function(){
                    uiHandleScroll();
                }, 150);
            });
        } else {
            // If screen width is greater than 991 pixels and .side-scroll is added to #page-container
            if ($windowW > 991 && $lPage.hasClass('side-scroll')) {
                // Turn scroll lock off (sidebar and side overlay - slimScroll will take care of it)
                jQuery($lSidebar).scrollLock('disable');
                jQuery($lSideOverlay).scrollLock('disable');

                // If sidebar scrolling does not exist init it..
                if ($lSidebarScroll.length && (!$lSidebarScroll.parent('.slimScrollDiv').length)) {
                    $lSidebarScroll.slimScroll({
                        height: $lSidebar.outerHeight(),
                        color: '#fff',
                        size: '5px',
                        opacity : .35,
                        wheelStep : 15,
                        distance : '2px',
                        railVisible: false,
                        railOpacity: 1
                    });
                }
                else { // ..else resize scrolling height
                    $lSidebarScroll
                        .add($lSidebarScroll.parent())
                        .css('height', $lSidebar.outerHeight());
                }

                // If side overlay scrolling does not exist init it..
                if ($lSideOverlayScroll.length && (!$lSideOverlayScroll.parent('.slimScrollDiv').length)) {
                    $lSideOverlayScroll.slimScroll({
                        height: $lSideOverlay.outerHeight(),
                        color: '#000',
                        size: '5px',
                        opacity : .35,
                        wheelStep : 15,
                        distance : '2px',
                        railVisible: false,
                        railOpacity: 1
                    });
                }
                else { // ..else resize scrolling height
                    $lSideOverlayScroll
                        .add($lSideOverlayScroll.parent())
                        .css('height', $lSideOverlay.outerHeight());
                }
            } else {
                // Turn scroll lock on (sidebar and side overlay)
                jQuery($lSidebar).scrollLock();
                jQuery($lSideOverlay).scrollLock();

                // If sidebar scrolling exists destroy it..
                if ($lSidebarScroll.length && $lSidebarScroll.parent('.slimScrollDiv').length) {
                    $lSidebarScroll
                        .slimScroll({destroy: true});
                    $lSidebarScroll
                        .attr('style', '');
                }

                // If side overlay scrolling exists destroy it..
                if ($lSideOverlayScroll.length && $lSideOverlayScroll.parent('.slimScrollDiv').length) {
                    $lSideOverlayScroll
                        .slimScroll({destroy: true});
                    $lSideOverlayScroll
                        .attr('style', '');
                }
            }
        }
    };

    // Layout API
    var uiLayoutApi = function($mode) {
        var $windowW = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

        // Mode selection
        switch($mode) {
            case 'sidebar_pos_toggle':
                $lPage.toggleClass('sidebar-l sidebar-r');
                break;
            case 'sidebar_pos_left':
                $lPage
                    .removeClass('sidebar-r')
                    .addClass('sidebar-l');
                break;
            case 'sidebar_pos_right':
                $lPage
                    .removeClass('sidebar-l')
                    .addClass('sidebar-r');
                break;
            case 'sidebar_toggle':
                if ($windowW > 991) {
                    $lPage.toggleClass('sidebar-o');
                } else {
                    $lPage.toggleClass('sidebar-o-xs');
                }
                break;
            case 'sidebar_open':
                if ($windowW > 991) {
                    $lPage.addClass('sidebar-o');
                } else {
                    $lPage.addClass('sidebar-o-xs');
                }
                break;
            case 'sidebar_close':
                if ($windowW > 991) {
                    $lPage.removeClass('sidebar-o');
                } else {
                    $lPage.removeClass('sidebar-o-xs');
                }
                break;
            case 'sidebar_mini_toggle':
                if ($windowW > 991) {
                    $lPage.toggleClass('sidebar-mini');
                }
                break;
            case 'sidebar_mini_on':
                if ($windowW > 991) {
                    $lPage.addClass('sidebar-mini');
                }
                break;
            case 'sidebar_mini_off':
                if ($windowW > 991) {
                    $lPage.removeClass('sidebar-mini');
                }
                break;
            case 'side_overlay_toggle':
                $lPage.toggleClass('side-overlay-o');
                break;
            case 'side_overlay_open':
                $lPage.addClass('side-overlay-o');
                break;
            case 'side_overlay_close':
                $lPage.removeClass('side-overlay-o');
                break;
            case 'side_overlay_hoverable_toggle':
                $lPage.toggleClass('side-overlay-hover');
                break;
            case 'side_overlay_hoverable_on':
                $lPage.addClass('side-overlay-hover');
                break;
            case 'side_overlay_hoverable_off':
                $lPage.removeClass('side-overlay-hover');
                break;
            case 'header_fixed_toggle':
                $lPage.toggleClass('header-navbar-fixed');
                break;
            case 'header_fixed_on':
                $lPage.addClass('header-navbar-fixed');
                break;
            case 'header_fixed_off':
                $lPage.removeClass('header-navbar-fixed');
                break;
            case 'side_scroll_toggle':
                $lPage.toggleClass('side-scroll');
                uiHandleScroll();
                break;
            case 'side_scroll_on':
                $lPage.addClass('side-scroll');
                uiHandleScroll();
                break;
            case 'side_scroll_off':
                $lPage.removeClass('side-scroll');
                uiHandleScroll();
                break;
            default:
                return false;
        }
    };

    // Main navigation functionality
    var uiNav = function() {
        // When a submenu link is clicked
        jQuery('[data-toggle="nav-submenu"]').on('click', function(e){
            // Get link
            var $link = jQuery(this);

            // Get link's parent
            var $parentLi = $link.parent('li');

            if ($parentLi.hasClass('open')) { // If submenu is open, close it..
                $parentLi.removeClass('open');
            } else { // .. else if submenu is closed, close all other (same level) submenus first before open it
                $link
                    .closest('ul')
                    .find('> li')
                    .removeClass('open');

                $parentLi
                    .addClass('open');
            }

            // Remove focus from submenu link
            if ($lHtml.hasClass('no-focus')) {
                $link.blur();
            }

            return false;
        });
    };

    // Blocks options functionality
    var uiBlocks = function() {
        // Init default icons fullscreen and content toggle buttons
        uiBlocksApi(false, 'init');

        // Call blocks API on option button click
        jQuery('[data-toggle="block-option"]').on('click', function(){
            uiBlocksApi(jQuery(this).closest('.block'), jQuery(this).data('action'));
        });
    };

    // Blocks API
    var uiBlocksApi = function($block, $mode) {
        // Set default icons for fullscreen and content toggle buttons
        var $iconFullscreen         = 'si si-size-fullscreen';
        var $iconFullscreenActive   = 'si si-size-actual';
        var $iconContent            = 'si si-arrow-up';
        var $iconContentActive      = 'si si-arrow-down';

        if ($mode === 'init') {
            // Auto add the default toggle icons to fullscreen and content toggle buttons
            jQuery('[data-toggle="block-option"][data-action="fullscreen_toggle"]').each(function(){
                var $this = jQuery(this);

                $this.html('<i class="' + (jQuery(this).closest('.block').hasClass('block-opt-fullscreen') ? $iconFullscreenActive : $iconFullscreen) + '"></i>');
            });

            jQuery('[data-toggle="block-option"][data-action="content_toggle"]').each(function(){
                var $this = jQuery(this);

                $this.html('<i class="' + ($this.closest('.block').hasClass('block-opt-hidden') ? $iconContentActive : $iconContent) + '"></i>');
            });
        } else {
            // Get block element
            var $elBlock = ($block instanceof jQuery) ? $block : jQuery($block);

            // If element exists, procceed with blocks functionality
            if ($elBlock.length) {
                // Get block option buttons if exist (need them to update their icons)
                var $btnFullscreen  = jQuery('[data-toggle="block-option"][data-action="fullscreen_toggle"]', $elBlock);
                var $btnToggle      = jQuery('[data-toggle="block-option"][data-action="content_toggle"]', $elBlock);

                // Mode selection
                switch($mode) {
                    case 'fullscreen_toggle':
                        $elBlock.toggleClass('block-opt-fullscreen');

                        // Enable/disable scroll lock to block
                        if ($elBlock.hasClass('block-opt-fullscreen')) {
                            jQuery($elBlock).scrollLock();
                        } else {
                            jQuery($elBlock).scrollLock('disable');
                        }

                        // Update block option icon
                        if ($btnFullscreen.length) {
                            if ($elBlock.hasClass('block-opt-fullscreen')) {
                                jQuery('i', $btnFullscreen)
                                    .removeClass($iconFullscreen)
                                    .addClass($iconFullscreenActive);
                            } else {
                                jQuery('i', $btnFullscreen)
                                    .removeClass($iconFullscreenActive)
                                    .addClass($iconFullscreen);
                            }
                        }
                        break;
                    case 'fullscreen_on':
                        $elBlock.addClass('block-opt-fullscreen');

                        // Enable scroll lock to block
                        jQuery($elBlock).scrollLock();

                        // Update block option icon
                        if ($btnFullscreen.length) {
                            jQuery('i', $btnFullscreen)
                                .removeClass($iconFullscreen)
                                .addClass($iconFullscreenActive);
                        }
                        break;
                    case 'fullscreen_off':
                        $elBlock.removeClass('block-opt-fullscreen');

                        // Disable scroll lock to block
                        jQuery($elBlock).scrollLock('disable');

                        // Update block option icon
                        if ($btnFullscreen.length) {
                            jQuery('i', $btnFullscreen)
                                .removeClass($iconFullscreenActive)
                                .addClass($iconFullscreen);
                        }
                        break;
                    case 'content_toggle':
                        $elBlock.toggleClass('block-opt-hidden');

                        // Update block option icon
                        if ($btnToggle.length) {
                            if ($elBlock.hasClass('block-opt-hidden')) {
                                jQuery('i', $btnToggle)
                                    .removeClass($iconContent)
                                    .addClass($iconContentActive);
                            } else {
                                jQuery('i', $btnToggle)
                                    .removeClass($iconContentActive)
                                    .addClass($iconContent);
                            }
                        }
                        break;
                    case 'content_hide':
                        $elBlock.addClass('block-opt-hidden');

                        // Update block option icon
                        if ($btnToggle.length) {
                            jQuery('i', $btnToggle)
                                .removeClass($iconContent)
                                .addClass($iconContentActive);
                        }
                        break;
                    case 'content_show':
                        $elBlock.removeClass('block-opt-hidden');

                        // Update block option icon
                        if ($btnToggle.length) {
                            jQuery('i', $btnToggle)
                                .removeClass($iconContentActive)
                                .addClass($iconContent);
                        }
                        break;
                    case 'refresh_toggle':
                        $elBlock.toggleClass('block-opt-refresh');

                        // Return block to normal state if the demostration mode is on in the refresh option button - data-action-mode="demo"
                        if (jQuery('[data-toggle="block-option"][data-action="refresh_toggle"][data-action-mode="demo"]', $elBlock).length) {
                            setTimeout(function(){
                                $elBlock.removeClass('block-opt-refresh');
                            }, 2000);
                        }
                        break;
                    case 'state_loading':
                        $elBlock.addClass('block-opt-refresh');
                        break;
                    case 'state_normal':
                        $elBlock.removeClass('block-opt-refresh');
                        break;
                    case 'close':
                        $elBlock.hide();
                        break;
                    case 'open':
                        $elBlock.show();
                        break;
                    default:
                        return false;
                }
            }
        }
    };

    // Material inputs helper
    var uiForms = function() {
        jQuery('.form-material.floating > .form-control').each(function(){
            var $input  = jQuery(this);
            var $parent = $input.parent('.form-material');

            if ($input.val()) {
                $parent.addClass('open');
            }

            $input.on('change', function(){
                if ($input.val()) {
                    $parent.addClass('open');
                } else {
                    $parent.removeClass('open');
                }
            });
        });
    };

    // Set active color themes functionality
    var uiHandleTheme = function() {
        var $cssTheme = jQuery('#css-theme');
        var $cookies  = $lPage.hasClass('enable-cookies') ? true : false;

        // If cookies are enabled
        if ($cookies) {
            var $theme  = Cookies.get('colorTheme') ? Cookies.get('colorTheme') : false;

            // Update color theme
            if ($theme) {
                if ($theme === 'default') {
                    if ($cssTheme.length) {
                        $cssTheme.remove();
                    }
                } else {
                    if ($cssTheme.length) {
                        $cssTheme.attr('href', $theme);
                    } else {
                        jQuery('#css-main')
                            .after('<link rel="stylesheet" id="css-theme" href="' + $theme + '">');
                    }
                }
            }

            $cssTheme = jQuery('#css-theme');
        }

        // Set the active color theme link as active
        jQuery('[data-toggle="theme"][data-theme="' + ($cssTheme.length ? $cssTheme.attr('href') : 'default') + '"]')
            .parent('li')
            .addClass('active');

        // When a color theme link is clicked
        jQuery('[data-toggle="theme"]').on('click', function(){
            var $this   = jQuery(this);
            var $theme  = $this.data('theme');

            // Set this color theme link as active
            jQuery('[data-toggle="theme"]')
                .parent('li')
                .removeClass('active');

            jQuery('[data-toggle="theme"][data-theme="' + $theme + '"]')
                .parent('li')
                .addClass('active');

            // Update color theme
            if ($theme === 'default') {
                if ($cssTheme.length) {
                    $cssTheme.remove();
                }
            } else {
                if ($cssTheme.length) {
                    $cssTheme.attr('href', $theme);
                } else {
                    jQuery('#css-main')
                        .after('<link rel="stylesheet" id="css-theme" href="' + $theme + '">');
                }
            }

            $cssTheme = jQuery('#css-theme');

            // If cookies are enabled, save the new active color theme
            if ($cookies) {
                Cookies.set('colorTheme', $theme, { expires: 7 });
            }
        });
    };

    // Scroll to element animation helper
    var uiScrollTo = function() {
        jQuery('[data-toggle="scroll-to"]').on('click', function(){
            var $this   = jQuery(this);
            var $target = $this.data('target');
            var $speed  = $this.data('speed') ? $this.data('speed') : 1000;

            jQuery('html, body').animate({
                scrollTop: jQuery($target).offset().top
            }, $speed);
        });
    };

    // Toggle class helper
    var uiToggleClass = function() {
        jQuery('[data-toggle="class-toggle"]').on('click', function(){
            var $el = jQuery(this);

            jQuery($el.data('target').toString()).toggleClass($el.data('class').toString());

            if ($lHtml.hasClass('no-focus')) {
                $el.blur();
            }
        });
    };

    // Add the correct copyright year
    var uiYearCopy = function() {
        var $date       = new Date();
        var $yearCopy   = jQuery('.js-year-copy');

        if ($date.getFullYear() === 2015) {
            $yearCopy.html('2015');
        } else {
            $yearCopy.html('2015-' + $date.getFullYear().toString().substr(2,2));
        }
    };

    // Manage page loading screen functionality
    var uiLoader = function($mode) {
        var $lpageLoader = jQuery('#page-loader');

        if ($mode === 'show') {
            if ($lpageLoader.length) {
                $lpageLoader.fadeIn(250);
            } else {
                $lBody.prepend('<div id="page-loader"></div>');
            }
        } else if ($mode === 'hide') {
            if ($lpageLoader.length) {
                $lpageLoader.fadeOut(250);
            }
        }

        return false;
    };

    /*
     ********************************************************************************************
     *
     * UI HELPERS (ON DEMAND)
     *
     * Third party plugin inits or various custom user interface helpers to extend functionality
     * They need to be called in a page to be initialized. They are included here to be easy to
     * init them on demand on multiple pages (usually repeated init code in common components)
     *
     ********************************************************************************************
     */

    /*
     * Print Page functionality
     *
     * App.initHelper('print-page');
     *
     */
    var uiHelperPrint = function() {
        // Store all #page-container classes
        var $pageCls = $lPage.prop('class');

        // Remove all classes from #page-container
        $lPage.prop('class', '');

        // Print the page
        window.print();

        // Restore all #page-container classes
        $lPage.prop('class', $pageCls);
    };

    /*
     * Custom Table functionality such as section toggling or checkable rows
     *
     * App.initHelper('table-tools');
     *
     */

    // Table sections functionality
    var uiHelperTableToolsSections = function(){
        // For each table
        jQuery('.js-table-sections').each(function(){
            var $table = jQuery(this);

            // When a row is clicked in tbody.js-table-sections-header
            jQuery('.js-table-sections-header > tr', $table).on('click', function(e) {
                var $row    = jQuery(this);
                var $tbody  = $row.parent('tbody');

                if (! $tbody.hasClass('open')) {
                    jQuery('tbody', $table).removeClass('open');
                }

                $tbody.toggleClass('open');
            });
        });
    };

    // Checkable table functionality
    var uiHelperTableToolsCheckable = function() {
        // For each table
        jQuery('.js-table-checkable').each(function(){
            var $table = jQuery(this);

            // When a checkbox is clicked in thead
            jQuery('thead input:checkbox', $table).on('click', function() {
                var $checkedStatus = jQuery(this).prop('checked');

                // Check or uncheck all checkboxes in tbody
                jQuery('tbody input:checkbox', $table).each(function() {
                    var $checkbox = jQuery(this);

                    $checkbox.prop('checked', $checkedStatus);
                    uiHelperTableToolscheckRow($checkbox, $checkedStatus);
                });
            });

            // When a checkbox is clicked in tbody
            jQuery('tbody input:checkbox', $table).on('click', function() {
                var $checkbox = jQuery(this);

                uiHelperTableToolscheckRow($checkbox, $checkbox.prop('checked'));
            });

            // When a row is clicked in tbody
            jQuery('tbody > tr', $table).on('click', function(e) {
                if (e.target.type !== 'checkbox'
                        && e.target.type !== 'button'
                        && e.target.tagName.toLowerCase() !== 'a'
                        && !jQuery(e.target).parent('label').length) {
                    var $checkbox       = jQuery('input:checkbox', this);
                    var $checkedStatus  = $checkbox.prop('checked');

                    $checkbox.prop('checked', ! $checkedStatus);
                    uiHelperTableToolscheckRow($checkbox, ! $checkedStatus);
                }
            });
        });
    };

    // Checkable table functionality helper - Checks or unchecks table row
    var uiHelperTableToolscheckRow = function($checkbox, $checkedStatus) {
        if ($checkedStatus) {
            $checkbox
                .closest('tr')
                .addClass('active');
        } else {
            $checkbox
                .closest('tr')
                .removeClass('active');
        }
    };

    /*
     * jQuery Appear, for more examples you can check out https://github.com/bas2k/jquery.appear
     *
     * App.initHelper('appear');
     *
     */
    var uiHelperAppear = function(){
        // Add a specific class on elements (when they become visible on scrolling)
        jQuery('[data-toggle="appear"]').each(function(){
            var $windowW    = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            var $this       = jQuery(this);
            var $class      = $this.data('class') ? $this.data('class') : 'animated fadeIn';
            var $offset     = $this.data('offset') ? $this.data('offset') : 0;
            var $timeout    = ($lHtml.hasClass('ie9') || $windowW < 992) ? 0 : ($this.data('timeout') ? $this.data('timeout') : 0);

            $this.appear(function() {
                setTimeout(function(){
                    $this
                        .removeClass('visibility-hidden')
                        .addClass($class);
                }, $timeout);
            },{accY: $offset});
        });
    };

    /*
     * jQuery Appear + jQuery countTo, for more examples you can check out https://github.com/bas2k/jquery.appear and https://github.com/mhuggins/jquery-countTo
     *
     * App.initHelper('appear-countTo');
     *
     */
    var uiHelperAppearCountTo = function(){
        // Init counter functionality
        jQuery('[data-toggle="countTo"]').each(function(){
            var $this       = jQuery(this);
            var $after      = $this.data('after');
            var $before     = $this.data('before');
            var $speed      = $this.data('speed') ? $this.data('speed') : 1500;
            var $interval   = $this.data('interval') ? $this.data('interval') : 15;

            $this.appear(function() {
                $this.countTo({
                    speed: $speed,
                    refreshInterval: $interval,
                    onComplete: function() {
                        if($after) {
                            $this.html($this.html() + $after);
                        } else if ($before) {
                            $this.html($before + $this.html());
                        }
                    }
                });
            });
        });
    };

    /*
     * jQuery SlimScroll, for more examples you can check out http://rocha.la/jQuery-slimScroll
     *
     * App.initHelper('slimscroll');
     *
     */
    var uiHelperSlimscroll = function(){
        // Init slimScroll functionality
        jQuery('[data-toggle="slimscroll"]').each(function(){
            var $this       = jQuery(this);
            var $height     = $this.data('height') ? $this.data('height') : '200px';
            var $size       = $this.data('size') ? $this.data('size') : '5px';
            var $position   = $this.data('position') ? $this.data('position') : 'right';
            var $color      = $this.data('color') ? $this.data('color') : '#000';
            var $avisible   = $this.data('always-visible') ? true : false;
            var $rvisible   = $this.data('rail-visible') ? true : false;
            var $rcolor     = $this.data('rail-color') ? $this.data('rail-color') : '#999';
            var $ropacity   = $this.data('rail-opacity') ? $this.data('rail-opacity') : .3;

            $this.slimScroll({
                height: $height,
                size: $size,
                position: $position,
                color: $color,
                alwaysVisible: $avisible,
                railVisible: $rvisible,
                railColor: $rcolor,
                railOpacity: $ropacity
            });
        });
    };

    /*
     ********************************************************************************************
     *
     * All the following helpers require each plugin's resources (JS, CSS) to be included in order to work
     *
     ********************************************************************************************
     */

    /*
     * Magnific Popup functionality, for more examples you can check out http://dimsemenov.com/plugins/magnific-popup/
     *
     * App.initHelper('magnific-popup');
     *
     */
    var uiHelperMagnific = function(){
        // Simple Gallery init
        jQuery('.js-gallery').each(function(){
            jQuery(this).magnificPopup({
                delegate: 'a.img-link',
                type: 'image',
                mainClass: 'mfp-zoom',
                gallery: {
                    enabled: true
                }
            });
        });

        // Simple Gallery init
        jQuery('.js-vid-gallery').each(function(){
            jQuery(this).magnificPopup({
                delegate: 'a.vid-link',
                type: 'iframe',
                mainClass: 'mfp-zoom'
            });
        });

        // Advanced Gallery init
        jQuery('.js-gallery-advanced').each(function(){
            jQuery(this).magnificPopup({
                delegate: 'a.img-lightbox',
                type: 'image',
                mainClass: 'mfp-zoom',
                gallery: {
                    enabled: true
                }
            });
        });
    };

    /*
     * CKEditor init, for more examples you can check out http://ckeditor.com/
     *
     * App.initHelper('ckeditor');
     *
     */
    var uiHelperCkeditor = function(){
        // Disable auto init when contenteditable property is set to true
        CKEDITOR.disableAutoInline = true;

        // Init inline text editor
        if (jQuery('#js-ckeditor-inline').length) {
            CKEDITOR.inline('js-ckeditor-inline');
        }

        // Init full text editor
        if (jQuery('#js-ckeditor').length) {
            CKEDITOR.replace('js-ckeditor');
        }
    };

    /*
     * Summernote init, for more examples you can check out http://summernote.org/
     *
     * App.initHelper('summernote');
     *
     */
    var uiHelperSummernote = function(){
        // Init text editor in air mode (inline)
        jQuery('.js-summernote-air').summernote({
            airMode: true
          });

        // Init full text editor
        jQuery('.js-summernote').summernote({
            height: 350,
            minHeight: null,
            maxHeight: null
        });
    };

    /*
     * Slick init, for more examples you can check out http://kenwheeler.github.io/slick/
     *
     * App.initHelper('slick');
     *
     */
    var uiHelperSlick = function(){
        // Get each slider element (with .js-slider class)
        jQuery('.js-slider').each(function(){
            var $slider = jQuery(this);

            // Get each slider's init data
            var $sliderArrows       = $slider.data('slider-arrows') ? $slider.data('slider-arrows') : false;
            var $sliderDots         = $slider.data('slider-dots') ? $slider.data('slider-dots') : false;
            var $sliderNum          = $slider.data('slider-num') ? $slider.data('slider-num') : 1;
            var $sliderAuto         = $slider.data('slider-autoplay') ? $slider.data('slider-autoplay') : false;
            var $sliderAutoSpeed    = $slider.data('slider-autoplay-speed') ? $slider.data('slider-autoplay-speed') : 3000;

            // Init slick slider
            $slider.slick({
                arrows: $sliderArrows,
                dots: $sliderDots,
                slidesToShow: $sliderNum,
                autoplay: $sliderAuto,
                autoplaySpeed: $sliderAutoSpeed
            });
        });
    };

    /*
     * Bootstrap Datepicker init, for more examples you can check out https://github.com/eternicode/bootstrap-datepicker
     *
     * App.initHelper('datepicker');
     *
     */
    var uiHelperDatepicker = function(){
        // Init datepicker (with .js-datepicker and .input-daterange class)
        jQuery('.js-datepicker').add('.input-daterange').datepicker({
            weekStart: 1,
            autoclose: true,
            todayHighlight: true,
            language: $('html').attr('lang'),
        });
    };

    /*
     * Bootstrap Colorpicker init, for more examples you can check out http://mjolnic.com/bootstrap-colorpicker/
     *
     * App.initHelper('colorpicker');
     *
     */
    var uiHelperColorpicker = function(){
        // Get each colorpicker element (with .js-colorpicker class)
        jQuery('.js-colorpicker').each(function(){
            var $colorpicker = jQuery(this);

            // Get each colorpicker's init data
            var $colorpickerMode    = $colorpicker.data('colorpicker-mode') ? $colorpicker.data('colorpicker-mode') : 'hex';
            var $colorpickerinline  = $colorpicker.data('colorpicker-inline') ? true : false;

            // Init colorpicker
            $colorpicker.colorpicker({
                'format': $colorpickerMode,
                'inline': $colorpickerinline
            });
        });
    };

    /*
     * Masked Inputs, for more examples you can check out http://digitalbush.com/projects/masked-input-plugin/
     *
     * App.initHelper('masked-inputs');
     *
     */
    var uiHelperMaskedInputs = function(){
        // Init Masked Inputs
        // a - Represents an alpha character (A-Z,a-z)
        // 9 - Represents a numeric character (0-9)
        // * - Represents an alphanumeric character (A-Z,a-z,0-9)

        jQuery.mask.definitions['y'] = '[12]';
        jQuery.mask.definitions['m'] = '[01]';
        jQuery.mask.definitions['d'] = '[0-3]';

        var dobMask = jQuery('.js-masked-date').attr('placeholder');

        jQuery('.js-masked-date').mask('d9/m9/y999',{placeholder:dobMask});
        jQuery('.js-masked-date-dash').mask('99-99-9999');
        jQuery('.js-masked-phone').mask('(999) 999-9999');
        jQuery('.js-masked-phone-ext').mask('(999) 999-9999? x99999');
        jQuery('.js-masked-taxid').mask('99-9999999');
        jQuery('.js-masked-ssn').mask('999-99-9999');
        jQuery('.js-masked-pkey').mask('a*-999-a999');
        jQuery('.js-masked-time').mask('99:99');
    };

    /*
     * Tags Inputs, for more examples you can check out https://github.com/xoxco/jQuery-Tags-Input
     *
     * App.initHelper('tags-inputs');
     *
     */
    var uiHelperTagsInputs = function(){
        // Init Tags Inputs (with .js-tags-input class)
        jQuery('.js-tags-input').tagsInput({
            height: '36px',
            width: '100%',
            defaultText: 'Add tag',
            removeWithBackspace: true,
            delimiter: [',']
        });
    };

    /*
     * Select2, for more examples you can check out https://github.com/select2/select2
     *
     * App.initHelper('select2');
     *
     */
    var uiHelperSelect2 = function(){
        // Init Select2 (with .js-select2 class)
        jQuery('.js-select2').select2({
            width: '100%'
        }).addClass('select2-pristine')
        .on('select2:select', function (e) {
          $(this).addClass('select2-selected').removeClass('select2-pristine');
        })
        .on('select2:unselect', function (e) {
          $(this).removeClass('select2-selected').removeClass('select2-pristine');
        });
    };

    /*
     * Selectize, for more examples you can check out https://github.com/selectize/selectize.js
     *
     * App.initHelper('selectize');
     *
     */
    var uiHelperSelectize = function(){
        // Init Selectize (with .js-selectize class)
        jQuery('.js-selectize').selectize({
            plugins: ['remove_button'],
            sortField: [
                    {
                        field: 'order',
                        direction: 'desc'
                    }
               ],
            onInitialize: function () {
                    // Extend Selectize to include any data attributes
                    // in the option value.
                    var s = this;
                    this.revertSettings.$children.each(function () {
                        $.extend(s.options[this.value], $(this).data());
                    });
                    // Detect if we should allow an empty value
                    if (s.$input.data().hasOwnProperty('allowempty')) {
                        s.settings.allowEmptyOption = s.$input.data('allowempty');
                        // Add the data-all value or placeholder to the select box as a global option
                        if (s.$input.data('all')) {
                            s.addOption({'text': s.$input.data('all'), 'value': '', 'order': 1});
                        } else if(s.$input.attr('placeholder')){
                            s.addOption({'text': s.$input.attr('placeholder'), 'value': '', 'order': 1});
                        }
                    }

                }
        });
    };

    /*
     * Highlight.js, for more examples you can check out https://highlightjs.org/usage/
     *
     * App.initHelper('highlightjs');
     *
     */
    var uiHelperHighlightjs = function(){
        // Init Highlight.js
        hljs.initHighlightingOnLoad();
    };

    /*
     * Bootstrap Notify, for more examples you can check out http://bootstrap-growl.remabledesigns.com/
     *
     * App.initHelper('notify');
     *
     */
    var uiHelperNotify = function(){
        // Init notifications (with .js-notify class)
        jQuery('.js-notify').on('click', function(){
            var $notify         = jQuery(this);
            var $notifyMsg      = $notify.data('notify-message');
            var $notifyType     = $notify.data('notify-type') ? $notify.data('notify-type') : 'info';
            var $notifyFrom     = $notify.data('notify-from') ? $notify.data('notify-from') : 'top';
            var $notifyAlign    = $notify.data('notify-align') ? $notify.data('notify-align') : 'right';
            var $notifyIcon     = $notify.data('notify-icon') ? $notify.data('notify-icon') : '';
            var $notifyUrl      = $notify.data('notify-url') ? $notify.data('notify-url') : '';

            jQuery.notify({
                    icon: $notifyIcon,
                    message: $notifyMsg,
                    url: $notifyUrl
                },
                {
                    element: 'body',
                    type: $notifyType,
                    allow_dismiss: true,
                    newest_on_top: true,
                    showProgressbar: false,
                    placement: {
                        from: $notifyFrom,
                        align: $notifyAlign
                    },
                    offset: 20,
                    spacing: 10,
                    z_index: 1033,
                    delay: 5000,
                    timer: 1000,
                    animate: {
                        enter: 'animated fadeIn',
                        exit: 'animated fadeOutDown'
                    }
                });
        });
    };

    /*
     * Draggable items with jQuery, for more examples you can check out https://jqueryui.com/sortable/
     *
     * App.initHelper('draggable-items');
     *
     */
    var uiHelperDraggableItems = function(){
        // Init draggable items functionality (with .js-draggable-items class)
        jQuery('.js-draggable-items > .draggable-column').sortable({
            connectWith: '.draggable-column',
            items: '.draggable-item',
            dropOnEmpty: true,
            opacity: .75,
            handle: '.draggable-handler',
            placeholder: 'draggable-placeholder',
            tolerance: 'pointer',
            start: function(e, ui){
                ui.placeholder.css({
                    'height': ui.item.outerHeight(),
                    'margin-bottom': ui.item.css('margin-bottom')
                });
            }
        });
    };

    /*
     * Easy Pie Chart, for more examples you can check out http://rendro.github.io/easy-pie-chart/
     *
     * App.initHelper('easy-pie-chart');
     *
     */
    var uiHelperEasyPieChart = function(){
        // Init Easy Pie Charts (with .js-pie-chart class)
        jQuery('.js-pie-chart').easyPieChart({
            barColor: jQuery(this).data('bar-color') ? jQuery(this).data('bar-color') : '#777777',
            trackColor: jQuery(this).data('track-color') ? jQuery(this).data('track-color') : '#eeeeee',
            lineWidth: jQuery(this).data('line-width') ? jQuery(this).data('line-width') : 3,
            size: jQuery(this).data('size') ? jQuery(this).data('size') : '80',
            animate: 750,
            scaleColor: jQuery(this).data('scale-color') ? jQuery(this).data('scale-color') : false
        });
    };

    /*
     * Bootstrap Maxlength, for more examples you can check out https://github.com/mimo84/bootstrap-maxlength
     *
     * App.initHelper('maxlength');
     *
     */
    var uiHelperMaxlength = function(){
        // Init Bootstrap Maxlength (with .js-maxlength class)
        jQuery('.js-maxlength').each(function(){
            var $input = jQuery(this);

            $input.maxlength({
                alwaysShow: $input.data('always-show') ? true : false,
                threshold: $input.data('threshold') ? $input.data('threshold') : 10,
                warningClass: $input.data('warning-class') ? $input.data('warning-class') : 'label label-warning',
                limitReachedClass: $input.data('limit-reached-class') ? $input.data('limit-reached-class') : 'label label-danger',
                placement: $input.data('placement') ? $input.data('placement') : 'bottom',
                preText: $input.data('pre-text') ? $input.data('pre-text') : '',
                separator: $input.data('separator') ? $input.data('separator') : '/',
                postText: $input.data('post-text') ? $input.data('post-text') : ''
            });
        });
    };

    /*
     * Bootstrap Datetimepicker, for more examples you can check out https://github.com/Eonasdan/bootstrap-datetimepicker
     *
     * App.initHelper('datetimepicker');
     *
     */
    var uiHelperDatetimepicker = function(){
        // Init Bootstrap Datetimepicker (with .js-datetimepicker class)
        jQuery('.js-datetimepicker').each(function(){
            var $input = jQuery(this);
            var tooltips = {};

            if ($input.data('locale') && $input.data('locale').indexOf('en') === -1) {
                tooltips = {
                    today: '',
                    clear: '',
                    close: '',
                    selectMonth: '',
                    prevMonth: '',
                    nextMonth: '',
                    selectYear: '',
                    prevYear: '',
                    nextYear: '',
                    selectDecade: '',
                    prevDecade: '',
                    nextDecade: '',
                    prevCentury: '',
                    nextCentury: '',
                    incrementHour: '',
                    pickHour: '',
                    decrementHour:'',
                    incrementMinute: '',
                    pickMinute: '',
                    decrementMinute:'',
                    incrementSecond: '',
                    pickSecond: '',
                    decrementSecond:'',
                }
            }

            $input.datetimepicker({
                format: $input.data('format') ? $input.data('format') : false,
                stepping: $input.data('stepping') ? $input.data('stepping') : 5,
                useCurrent: $input.data('use-current') ? $input.data('use-current') : false,
                locale: moment.locale('' + ($input.data('locale') ? $input.data('locale') : '') +''),
                showTodayButton: $input.data('show-today-button') ? $input.data('show-today-button') : false,
                showClear: $input.data('show-clear') ? $input.data('show-clear') : false,
                showClose: $input.data('show-close') ? $input.data('show-close') : false,
                sideBySide: $input.data('side-by-side') ? $input.data('side-by-side') : false,
                inline: $input.data('inline') ? $input.data('inline') : false,
                icons: {
                    time: 'si si-clock',
                    date: 'si si-calendar',
                    up: 'si si-arrow-up',
                    down: 'si si-arrow-down',
                    previous: 'si si-arrow-left',
                    next: 'si si-arrow-right',
                    today: 'si si-size-actual',
                    clear: 'si si-trash',
                    close: 'si si-close'
                },
                tooltips: tooltips,
                widgetParent: 'body'
            });

            $input.on('dp.show', function(e) {
                e.stopPropagation();
                var datepicker = $('body').find('.bootstrap-datetimepicker-widget:last');
                if (datepicker.hasClass('bottom')) {
                    var top = $(this).offset().top + $(this).outerHeight();
                    var left = $(this).offset().left;
                    datepicker.css({
                      'top': top + 'px',
                      'bottom': 'auto',
                      'left': left + 'px'
                  });
                } else if (datepicker.hasClass('top')) {
                    var top = $(this).offset().top - datepicker.outerHeight();
                    var left = $(this).offset().left;
                    datepicker.css({
                      'top': top + 'px',
                      'bottom': 'auto',
                      'left': left + 'px'
                  });
                }
            });

        });
    };

    /*
     * Ion Range Slider, for more examples you can check out https://github.com/IonDen/ion.rangeSlider
     *
     * App.initHelper('rangeslider');
     *
     */
    var uiHelperRangeslider = function(){
        // Init Ion Range Slider (with .js-rangeslider class)
        jQuery('.js-rangeslider').each(function(){
            var $input = jQuery(this);

            $input.ionRangeSlider({
                input_values_separator: ';'
            });
        });
    };

    /*
     * Bootstrap Calendar, for more examples you can check out https://github.com/Serhioromano/bootstrap-calendar
     *
     * App.initHelper('bootstrap-calendar');
     *
     */
    var uiHelperBootstrapCalendar = function(){
        // Init bootstrap Calendar functionality
        jQuery('.js-bootstrapCalendar').each(function(){
            var $this       = jQuery(this);

            var calendar = $this.calendar(
            {
                tmpl_path: "/js/plugins/bootstrap-calendar/tmpls/",
                language: $lLang,
                weekbox: false,
                modal: null,
                onAfterViewLoad: function(view) {
                    // Set the month title
                    $('#calendar-title').text(this.getTitle());

                    // Disable default calendar click events on days
                    $('*[data-cal-date]').unbind('click');

                    // When clicking a day on the cal, scroll the events list to that location
                    $(".cal-month-day").click(function(e) {

                        // Get the date clicked and the events
                        var date = $(this).find('span').attr('data-cal-date');
                        var $events = jQuery('#eventLog .nav-users li');
                        
                        // Map the events to date / id
                        var $eventDates = $events.map(function(){
                            return {
                                id: jQuery(this).data('event-id'),
                                date: jQuery(this).data('event-date')
                            };
                        }).get();

                        // Calculate the closest date to the one clicked clicked
                        var nearestDates = _.filter($eventDates,     
                        function(each){ 
                            return moment.utc(each.date).isSameOrAfter(moment(date).endOf('day'), 'day');
                        });

                        // Get the scroll container
                        $scroller = jQuery('#eventLog').find('[data-toggle="slimscroll"]');

                        // If we've calculated the nearest events successfully...
                        if (nearestDates.length) {
                            // Get the closest valid day with events from the selected date
                            var closestDate = moment.utc(nearestDates[nearestDates.length-1].date).format('YYYY-MM-DD');
                            // Get the best event for the day selected
                            var $closestEvent = $('[data-event-date *= ' + closestDate + ']').first();
                            // Scroll to the event
                            $scroller.slimScroll({scrollTo: $closestEvent.position().top, animate: true});                        
                        } else{
                            e.preventDefault();
                            return false;
                        }

                        // Clear any existing highlight classes
                        $events.removeClass('animated highlight closest');

                        $closestEvent.addClass('closest');

                        // If there's an event on the day clicked, highlight that event in the list
                        $eventsOnDate = $events.filter(function(key, event){
                            return moment($(event).data('event-date')).isSame(date,'day');
                        });
                        setTimeout(function() {
                            $eventsOnDate.addClass('animated highlight');
                        }, 100);

                        e.preventDefault();
                        return false;
                    });
                    
                },
                onBeforeEventsLoad: function (next) {
                    $this.closest('.block').addClass('block-opt-refresh');
                    next();
                },
                onAfterEventsLoad: function () {
                    $this.closest('.block').removeClass('block-opt-refresh');
                },
                events_source: $this.data('source')
            });      

            $('[data-calendar-nav]').each(function() {
                var $this = $(this);
                $this.click(function() {
                    calendar.navigate($this.data('calendar-nav'));
                });
            });
        });
    };

    /*
     * Bootstrap Calendar, for more examples you can check out https://github.com/Serhioromano/bootstrap-calendar
     *
     * App.initHelper('bootstrap-calendar');
     *
     */
    var uiHelperAppearScrollCalendar = function(){
    jQuery('[data-toggle="scrollCalendar"]').each(function(){
            var $this       = jQuery(this);
            var date      = $this.data('event-date');

              // $this.on('appear', function(event, $all_appeared_elements) {
              //     // this element is now inside browser viewport
              //   });
        });
    };

    /*
     * jstimezonedetect - Detect the user's timezone and fill a form element
     *
     * App.initHelper('jstimezonedetect');
     *
     */
    var uiHelperTimezoneDetect = function(){
        // Check for the presence of a timezone field
        jQuery('input#timezone').each(function(){
            var $input = jQuery(this);
            if (!$input.val()) {
                if (jstz.determine()) {
                   $input.val(jstz.determine().name());
                }
            }         
        });
    };

    /*
     * Data table search toggle
     *
     * App.initHelper('dataTableSearchToggle');
     *
     */
    var uiHelperDataTableSearchToggle = function(){
        $('.block-content').on('click', '.row.search', function(){
            var $this = $(this);
            $this.parents('.content-grid').find('.push').addClass('searching');
            $this.addClass('visible');
        });
    };

    /*
     * X-Editable, for more examples you can check out http://vitalets.github.io/x-editable/docs.html
     *
     * App.initHelper('editable');
     *
     */
    var uiHelperEditable = function(){
        // $('#username').editable();
        // Init Ion Range Slider (with .js-rangeslider class)
        jQuery('.x-editable').each(function(){
            var $input = jQuery(this);
            $input.editable();
        }).on('shown',function(e, editable){
            editable.input.$input.select();
            $(".js-masked-percent").mask("99%");
        });
    };

    /*
     * Patient Deletion
     *
     * App.initHelper('patientDeletion');
     *
     */
    var uiHelperPatientDeletion = function(){

        jQuery('#confirm-keep-patient').on('show.bs.modal', function (event) {
            var modal = $(this);
            var button = $(event.relatedTarget) // Button that triggered the modal
            var row = button.parents('tr');
            var table = row.parents('.table');

            var email = row.data('email');
            var name = row.data('name');
            var id = row.data('id');
            var status = row.data('status');
            var action = button.data('action');
            
            // Text replacement
            modal.find('[data-replace = "name"]').text(name);
            modal.find('[data-replace = "email"]').text(email);
            modal.find('p:contains(":name")').html(function () {
                return $(this).html().replace(':name', '<strong data-replace="name">' + name + '</strong>'); 
            });
            modal.find('p:contains(":email")').html(function () {
                return $(this).html().replace(':email', '<strong data-replace="email">' + email + '</strong>'); 
            });

            modal.on('hide.bs.modal', function (e) {
              modal.find('#submitKeep').unbind();
            });

            modal.find('#submitKeep').click(function (e) {
                $(this).prop('disabled', true);

                App.blocks('#' + modal.find('.block').attr('id'),'state_loading');
                return $.ajax({
                    url: action,
                    type: 'POST',
                    success: function(result) {

                        // Remove to request from the list
                        row.remove();

                        // If we've removed the last row, we can remove the flagged for removal section.
                        if (table.find('tr').length <= 1) {
                            table.parents('.row').addClass('animated slideHeightOut')
                        }

                        App.blocks('#' + modal.find('.block').attr('id'),'state_normal');
                        modal.find('#submitKeep').prop('disabled', false);
                        modal.modal('hide');

                    },
                    error: function (error) {
                        // alert('error');
                        App.blocks('#' + modal.find('.block').attr('id'),'state_normal');
                        modal.find('#submitKeep').prop('disabled', false);
                        console.warn(error);
                    }
                });
            });
        });

        jQuery('#confirm-delete-patient').on('show.bs.modal', function (event) {
            var modal = $(this);
            var button = $(event.relatedTarget) // Button that triggered the modal
            var row = button.parents('tr');
            var table = row.parents('.table');

            var email = row.data('email');
            var name = row.data('name');
            var id = row.data('id');
            var status = row.data('status');
            var action = button.data('action');

            // Text replacement
            modal.find('[data-replace = "name"]').text(name);
            modal.find('[data-replace = "email"]').text(email);
            modal.find('p:contains(":name")').html(function () {
                return $(this).html().replace(':name', '<strong data-replace="name">' + name + '</strong>'); 
            });
            modal.find('p:contains(":email")').html(function () {
                return $(this).html().replace(':email', '<strong data-replace="email">' + email + '</strong>'); 
            });

            if(status === 'acceptedShare'){
                modal.find('.visible-if-active').removeClass('hidden');
            } else{
                modal.find('.visible-if-active').addClass('hidden');
            }

            modal.on('hide.bs.modal', function (e) {
              modal.find('#submitDelete').unbind();
            });

            modal.find('#submitDelete').click(function (e) {
                $(this).prop('disabled', true);

                App.blocks('#' + modal.find('.block').attr('id'),'state_loading');
                return $.ajax({
                    url: action,
                    data: {"_method":"delete"},
                    dataType:'json',
                    type:'post',
                    success: function(result) {
                        
                        if (button.parents('.table.dataTable').length) {
                            // If the action comes from the datatable, just remove the row with the datatable API 
                            $('#patientsTable.dataTable').DataTable().row('[data-id="' + id + '"]').remove().draw( false );
                        } else{
                            // If the action comes from outside datatable, remove from the current table as well as the datatable
                            row.remove();
                            $('#patientsTable.dataTable').DataTable().row('[data-id="' + id + '"]').remove().draw( false );
                            // If we've removed the last row, we can remove the flagged for removal section.
                            if (table.find('tr').length <= 1) {
                                table.parents('.row').addClass('animated slideHeightOut')
                            }
                        }
                        App.blocks('#' + modal.find('.block').attr('id'),'state_normal');
                        modal.find('#submitDelete').prop('disabled', false);
                        modal.modal('hide');

                        if ($('#patientsTable.dataTable').DataTable().rows().count() === 0) {
                            location.reload();
                        }
                    },
                    error: function (error) {
                        // alert('error');
                        App.blocks('#' + modal.find('.block').attr('id'),'state_normal');
                        modal.find('#submitDelete').prop('disabled', false);
                        console.warn(error);
                    }
                });
            });
        });


        jQuery('#confirm-delete-hcp').on('show.bs.modal', function (event) {
            var modal = $(this);
            var button = $(event.relatedTarget) // Button that triggered the modal
            var row = button.parents('tr');
            var table = row.parents('.table');

            var email = row.data('email');
            var name = row.data('name');
            var id = row.data('id');
            var action = button.data('action');

            // Text replacement
            modal.find('[data-replace = "name"]').text(name);
            modal.find('[data-replace = "email"]').text(email);
            modal.find('p:contains(":name")').html(function () {
                return $(this).html().replace(':name', '<strong data-replace="name">' + name + '</strong>'); 
            });
            modal.find('p:contains(":email")').html(function () {
                return $(this).html().replace(':email', '<strong data-replace="email">' + email + '</strong>'); 
            });

            // Unbind submit action on removal
            modal.on('hide.bs.modal', function (e) {
              modal.find('#submitDelete').unbind();
            });

            modal.find('#submitDelete').click(function (e) {
                return $.ajax({
                    url: action,
                    data: {"_method":"delete"},
                    dataType:'json',
                    type:'post',
                    success: function(result) {
                        
                        if (button.parents('.table.dataTable').length) {
                            // If the action comes from the datatable, just remove the row with the datatable API 
                            $('#hcpsTable.dataTable').DataTable().row('[data-id="' + id + '"]').remove().draw( false );
                        } else{
                            // If the action comes from outside datatable, remove from the current table as well as the datatable
                            row.remove();
                            $('#hcpsTable.dataTable').DataTable().row('[data-id="' + id + '"]').remove().draw( false );
                            // If we've removed the last row, we can remove the flagged for removal section.
                            if (table.find('tr').length <= 1) {
                                table.parents('.row').addClass('animated slideHeightOut')
                            }
                        }
                        modal.modal('hide');

                        if ($('#hcpsTable.dataTable').DataTable().rows().count() === 0) {
                            location.reload();
                        }
                    },
                    error: function (error) {
                        // alert('error');
                        console.warn(error);
                    }
                });
            });
        });
    };

    /*
     * NHI Form Generation for the Taiwan region
     *
     * App.initHelper('nhiForm');
     *
     */
    var uiHelperNHIForm = function(){
        // console.log('NHI Form is calling');
        var nhiModal = $('#request-nhi-form');
        var selectedRowsArray = [];

        // generate NHI infusion events preview table
        nhiModal.ready(function() {
            // console.log('NHI form preview');

            // update date when 'from' date is changed
            $('#nhi-from').datepicker({
                format: 'yyyy/mm/dd',
                onSelect: function(d, i) {
                    if (d !== i.lastVal) {
                        $(this).change();
                    }
                }
            });

            // update date when 'to' date is changed
            $('#nhi-to').datepicker({
                format: 'yyyy/mm/dd',
                onSelect: function(d, i) {
                    if (d !== i.lastVal) {
                        $(this).change();
                    }
                }
            });

            var productNameColumn = 2;
            var lotNumberColumn = 5;

            var table = $('#nhi_form_table_preview').DataTable({
                autoWidth: false,
                info: false,
                processing: true,
                ordering: false,
                searching: true,
                paging: false,
                serverSide: false,
                deferLoading: 0,
                dom: 'rt',
                columns: [
                    {data: 'checkbox'},
                    {data: {
                        _: 'date.display',
                        filter: 'date.timestamp'
                    }},
                    {
                        data: 'product',
                        visible: false
                    },
                    {data: 'vial'},
                    {data: 'volume'},
                    {
                        data: 'lot', 
                        defaultContent: '<span class="text-muted">' + zb.nhiFormModal.trans.infusion_table.no_lot + '</span>'
                    },
                    {data: 'cause'},
                    {data: 'site'}
                ],
            });

            $.fn.dataTable.ext.search.push(function( settings, data, dataIndex ) {
                var fromDate = moment($('#nhi-from').val(), "YYYY/MM/DD");
                var toDate = moment($('#nhi-to').val(), "YYYY/MM/DD").endOf('day');
                var infusionDate = moment.utc(data[1], "YYYY/MM/DD kk:mm").local();
                // console.log('fromDate', fromDate, '\ntoDate', toDate, '\ninfusionDate', infusionDate);

                if ((isNaN(fromDate) && isNaN(toDate)) ||
                    (isNaN(fromDate) && infusionDate.isBefore(toDate)) ||
                    (infusionDate.isAfter(fromDate)) && isNaN(toDate) ||
                    (infusionDate.isBetween(fromDate, toDate)))
                {
                    return true;
                } else {
                    return false;
                }
            });

            function calculateDrugNameAndLot(rows) {
                // Group drugs used together by name, and print them in the format
                // Drug Name AA (Lot1, Lot2, Lot3), Druag Name BB (Lot6, Lot7, Lot8)
                if (rows) {
                    var products = table.rows(rows).data().pluck('product');
                    var lotNumbers = table.rows(rows).data().pluck('lot');
                } else{
                    var products = table.columns(productNameColumn).data().eq( 0 );
                    var lotNumbers = table.columns(lotNumberColumn).data().eq( 0 );
                }

                // 1. construct a product array
                var productArray = [];
                _.each(products, function(p) {
                    if (p) {
                        if (p.indexOf(' / ')) {
                            var pArray = p.split(' / ');
                            productArray = productArray.concat(pArray);
                        } else {
                            productArray.push(p);
                        }
                    }
                });
                // console.log('productArray', productArray);

                // 2. construct a lot number array
                var lotArray = [];
                _.each(lotNumbers, function(l, index) {
                    if (l) {
                        if (l.indexOf(' / ')) {
                            var lArray = l.split(' / ');
                            lotArray = lotArray.concat(lArray);
                        } else {
                            lotArray.push(l);
                        }
                    }
                });

                // 3. zip product and lot number
                var productAndLotNumberArray = _.zip(productArray, lotArray);
                productAndLotNumberArray = _.groupBy(productAndLotNumberArray, 0);

                // 4. construct a unique product name array
                var uniqProductArray = _.uniq(productArray).sort();

                // 5. loop through productAndLotNumerArray and construct an array
                // based on the name from the uniqProductArray
                var productWithLot = [];
                _.each(uniqProductArray, function(product) {
                    productWithLot[product] = [];
                    _.each(productAndLotNumberArray, function(pA) {
                        _.each(pA, function(p) {
                            if (p[0] == product && p[1] !== zb.nhiFormModal.trans.infusion_table.no_lot) {
                                productWithLot[product].push(p[1]);
                            }
                        });
                    });
                });

                // 6. print the drugs
                var productLotStrArray = [];
                for (var product in productWithLot) {
                    // console.log('product', product, '\tlot list', productWithLot[product]);
                    var productLotStr = product;
                    if (productWithLot[product].length) {
                        productLotStr += ' (' + productWithLot[product].join(', ') + ')';
                    }
                    // console.log('productLotStr', productLotStr);
                    if (productLotStr !== ' ()') {
                        productLotStrArray.push(productLotStr);
                    }
                }
                var allProductLotStr = productLotStrArray.join(', ');
                // console.log('allProductLotStr', allProductLotStr);
                $('input[name="drug_and_lot"]').attr('placeholder', allProductLotStr);
            };

            function rowsInDateRange() {
                table.draw();
                selectedRowsArray = [];
                if ($('input[name*="nhi_row"]').length > 0) {
                    $('input[name*="nhi_row"]').each(function(index, value) {
                        if (this.id) {
                            var eventIndex = parseInt(this.id.split('_').slice(-1).pop());
                            $('#nhi_row_' + eventIndex).prop('checked', true);
                            changeSelection(eventIndex, true);
                        }
                    });
                }
                // console.log('selected rows', selectedRowsArray);
                calculateDrugNameAndLot(selectedRowsArray);
            }

            $('#nhi-from').change(function() {
                // console.log('datepicker value is changed to', $(this).val());
                minDateFilter = new Date($(this).val()).getTime();
                // console.log(minDateFilter);
                rowsInDateRange();
            });

            $('#nhi-to').change(function() {
                // console.log('datepicker value is changed to', $(this).val());
                maxDateFilter = new Date($(this).val()).getTime();
                // console.log(maxDateFilter);
                rowsInDateRange();
            });
             
            // init status

            nhiModal.on('show.bs.modal', function(){
                rowsInDateRange();
                calculateDrugNameAndLot(selectedRowsArray);
                allSelected();
                // console.log(selectedRowsArray);
            });

            // check-all checkbox
            $('#check-all').click(function() {
                $('input[name*="nhi_row"]').not(this).prop('checked', this.checked);
                if ($('#check-all').is(':checked')) {
                    // console.log('check-all is checked');
                    $('tbody tr').removeClass('active');
                    allSelected();
                } else {
                    // console.log('check-all is unchecked');
                    noneSelected();
                }
                console.log(selectedRowsArray);
            });

            // Number.isNumber is part of ES6 so not supported by IE11
            // https://stackoverflow.com/questions/31720269/internet-explorer-11-object-doesnt-support-property-or-method-isinteger
            Number.isInteger = Number.isInteger || function(value) {
                return typeof value === "number" && 
                       isFinite(value) && 
                       Math.floor(value) === value;
            };

            // store selected rows into an array
            table.on('click', 'tr', function(event) {
                if (Number.isInteger(table.row(this).index())) {
                    var checkbox = $('#nhi_row_' + table.row(this).index());
                    // console.log('row No.', table.row(this).index(), 'clicked', 'checkbox was', checkbox.prop('checked'));
                    if (!($(event.target).is(':input'))) {
                        if (checkbox.is(':checked')) {
                            // console.log('unchecked');
                            checkbox.prop('checked', false);
                        } else {
                            // console.log('checked');
                            checkbox.prop('checked', true);
                        }                        
                    } else {
                        if (checkbox.is(':checked')) {
                            // console.log('checked');
                            checkbox.prop('checked', true);
                        } else {
                            // console.log('unchecked');
                            checkbox.prop('checked', false);
                        }  
                    }

                    changeSelection(table.row(this).index(), checkbox.is(':checked'));
                    console.log(selectedRowsArray);
                }
            });

            function remove(array, element) {
                return _.remove(selectedRowsArray, function(n) {
                   return n == element;
                });
            }

            function allSelected() {
                // console.log('\n\tall selected');
                $('input[name*="nhi_row"]').each(function(index, value) {
                    if (this.id) {
                        var eventIndex = parseInt(this.id.split('_').slice(-1).pop());
                        $('#nhi_row_' + eventIndex).prop('checked', true);
                        changeSelection(eventIndex, true);
                    }
                });
                selectedRowsArray = _.uniq(selectedRowsArray);
                calculateDrugNameAndLot(selectedRowsArray);
                return _.uniq(selectedRowsArray);
            }

            function noneSelected() {
                selectedRowsArray = [];
                calculateDrugNameAndLot(selectedRowsArray);
                return selectedRowsArray;
            }

            function changeSelection(index, isSelected) {
                if (isSelected === false) {
                    // console.log('index', index, 'will be removed');
                    remove(selectedRowsArray, index);
                } else if (isSelected) {
                    // console.log('index', index, 'will be added');
                    selectedRowsArray.push(index);
                }
                // return the selected row index from an array contains all infusion events
                selectedRowsArray = _.uniq(selectedRowsArray);
                calculateDrugNameAndLot(selectedRowsArray);
                return selectedRowsArray;
            }
        });

        $( "#nhiFormGeneration" ).submit(function( event ) {
            event.preventDefault();
            // console.log('nhi form is shown --- submit A function');

            nhiModal.find('#patientRequestNHIBlock').addClass('block-opt-refresh');
            form = this;

            setTimeout(function() {
                // console.log('nhi form is shown --- close function');
                nhiModal.modal('hide');
            }, 750);

            setTimeout( function () {
                // console.log('nhi form is shown --- submit B function');
                $('#selected_rows').val(selectedRowsArray); // update the selectedRowsArray to form's hidden element
                // reset/empty the selections
                $('#nhi_form_table_preview').DataTable().rows('.selected').deselect();
                $('input:checkbox').prop('checked', false);
                selectedRowsArray = [];
                $('#check-all').prop('checked', true);
                $('input[name*="nhi_row"]').prop('checked', true);
                form.submit();
            }, 1000);
            return;
        });

        nhiModal.on('hide.bs.modal', function (event) {
            // console.log('nhi form will be hidden');
            var modal = $(this);
            modal.find('#patientRequestNHIBlock').removeClass('block-opt-refresh');
        });

    };

    /*
     * MOH Form Generation for the Poland region
     *
     * App.initHelper('mohForm');
     *
     */
    var uiHelperMOHForm = function(){
        // console.log('\n\tcalled uiHelperMOHFORM\n');
        var mohModal = $('#request-moh-form');
        var submitButtonClickable = false;

        mohModal.find('#submitbuttonExcel').on('click', function(event){
            // console.log('moh form --- click excel function');
            // Submitting as Excel
            // Update format field
            $('#mohformformat').val('EXCEL');
            var form = $('#mohFormGeneration');
            // console.log('export format is', $('#mohformformat').val());

            var validateURL = form.attr('action') + '/validate?';
            $.get(validateURL  + $(form).serialize(), function (data) {
                if (data === 'true') {
                    submitButtonClickable = true;
                    // console.log('moh form --- click excel function');
                    return;
                } else {
                    submitButtonClickable = false;
                    $('.no-results').fadeIn();
                    $('#patientRequestMOHBlock').removeClass('block-opt-refresh');
                }
            });
            return;
        });

        mohModal.find('#submitbuttonPDF').on('click', function(event){
            // console.log('moh form --- click pdf function');
            // Submitting as PDF
            // Update format field
            submitButtonClickable = true;        
            $('#mohformformat').val('PDF');
            // console.log('export format is', $('#mohformformat').val());

            var form = $('#mohFormGeneration');
            return;
        });            

        mohModal.find('#mohFormGeneration').submit(function( event ) {
            // console.log('moh form --- submit function');
            event.preventDefault();
            mohModal.find('#patientRequestMOHBlock').addClass('block-opt-refresh');
            form = this;

            setTimeout(function() {
                if (submitButtonClickable) {
                    mohModal.modal('hide');
                }
            }, 750);
            
            setTimeout( function () {
                if (submitButtonClickable) {
                    form.submit();
                }
            }, 1000);
            return;
        });                

        jQuery('#request-moh-form').on('hide.bs.modal', function (event) {
            // console.log('moh form --- hide modal function');
            var modal = $(this);
            modal.find('#patientRequestMOHBlock').removeClass('block-opt-refresh');
            modal.find('.no-results').hide();
        });
    };

    /*
     * Product Usage Report Generation for the Poland region
     *
     * App.initHelper('productUsageReport');
     *
     */
    var uiHelperProductUsageReport = function(){
        // console.log('\n\tcalled uiHelperProductUsageReport\n');
        var productUsageModal = $('#request-product-usage-form');
        var submitButtonClickable = false;

        productUsageModal.find('#submit-button-excel-product-usage').on('click', function(event){
            // Submitting as Excel
            // Update format field
            $('#productreportformat').val('EXCEL');
            var form = $('#product-usage-report-generation');
            var validateURL = form.attr('action') + '/validate?';
            $.get(validateURL  + $(form).serialize(), function (data) {
                if (data === 'true') {
                    submitButtonClickable = true;
                    // console.log('productUsageModal --- click excel function');
                    return;
                } else {
                    submitButtonClickable = false;
                    $('.product-usage-report-no-results').fadeIn();
                    $('#product-usage-request-block').removeClass('block-opt-refresh');
                }
            });
            return;
        });

        productUsageModal.find('#submit-button-pdf-product-usage').on('click', function(event){
            // console.log('productUsageModal --- click pdf function');
            // Submitting as PDF
            // Update format field
            submitButtonClickable = true;        
            $('#productreportformat').val('PDF');
            var form = $('#product-usage-report-generation');
            var validateURL = form.attr('action') + '/validate?';
            $.get(validateURL  + $(form).serialize(), function (data) {
                if (data === 'true') {
                    submitButtonClickable = true;
                    // console.log('productUsageModal --- click excel function');
                    return;
                } else {
                    submitButtonClickable = false;
                    $('.product-usage-report-no-results').fadeIn();
                    $('#product-usage-request-block').removeClass('block-opt-refresh');
                }
            });
            return;
        });            

        productUsageModal.find('#product-usage-report-generation').submit(function( event ) {
            // console.log('productUsageModal --- submit function');
            event.preventDefault();
            productUsageModal.find('#product-usage-request-block').addClass('block-opt-refresh');
            form = this;

            setTimeout(function() {
                if (submitButtonClickable) {
                    productUsageModal.modal('hide');
                }
            }, 750);
            
            setTimeout( function () {
                if (submitButtonClickable) {
                    form.submit();
                }
            }, 1000);
            return;
        });                

        jQuery('#request-product-usage-form').on('hide.bs.modal', function (event) {
            // console.log('product usage --- hide modal function');
            var modal = $(this);
            modal.find('#product-usage-request-block').removeClass('block-opt-refresh');
            modal.find('.product-usage-report-no-results').hide();
        });
    };

    /*
     * Bleeding Report Generation for the Poland region
     *
     * App.initHelper('bleedingReport');
     *
     */
    var uiHelperBleedingReport = function(){
        // console.log('\n\tcalled uiHelperBleedingReport\n');
        var bleedingReportModal = $('#request-bleeding-report-form');
        var submitButtonClickable = false;

        bleedingReportModal.find('#submit-button-excel-bleeding-report').on('click', function(event){
            // Submitting as Excel
            // Update format field
            $('#bleedingreportformat').val('EXCEL');
            var form = $('#bleeding-report-generation');
            var validateURL = form.attr('action') + '/validate?';
            $.get(validateURL  + $(form).serialize(), function (data) {
                if (data === 'true') {
                    submitButtonClickable = true;
                    return;
                } else {
                    submitButtonClickable = false;
                    $('.bleeding-report-no-results').fadeIn();
                    $('#bleeding-report-request-block').removeClass('block-opt-refresh');
                }
            });
            // console.log('bleedingReportModal --- click excel function', $('#bleedingreportformat').val());
            return;
        });

        bleedingReportModal.find('#submit-button-pdf-bleeding-report').on('click', function(event){
            // Submitting as PDF
            // Update format field
            submitButtonClickable = true;        
            $('#bleedingreportformat').val('PDF');
            var form = $('#bleeding-report-generation');
            var validateURL = form.attr('action') + '/validate?';
            $.get(validateURL  + $(form).serialize(), function (data) {
                if (data === 'true') {
                    submitButtonClickable = true;
                    return;
                } else {
                    submitButtonClickable = false;
                    $('.bleeding-report-no-results').fadeIn();
                    $('#bleeding-report-request-block').removeClass('block-opt-refresh');
                }
            });
            // console.log('bleedingReportModal --- click pdf function', $('#bleedingreportformat').val());
            return;
        });            

        bleedingReportModal.find('#bleeding-report-generation').submit(function( event ) {
            // console.log('bleedingReportModal --- submit function');
            event.preventDefault();
            bleedingReportModal.find('#bleeding-report-request-block').addClass('block-opt-refresh');
            form = this;

            setTimeout(function() {
                if (submitButtonClickable) {
                    bleedingReportModal.modal('hide');
                }
            }, 750);
            
            setTimeout( function () {
                if (submitButtonClickable) {
                    form.submit();
                }
            }, 1000);
            return;
        });                

        jQuery('#request-bleeding-report-form').on('hide.bs.modal', function (event) {
            // console.log('bleeding report --- hide modal function');
            var modal = $(this);
            modal.find('#bleeding-report-request-block').removeClass('block-opt-refresh');
            modal.find('.bleeding-report-no-results').hide();
        });
    };

    /*
     * Bleeds Treatment Report Generation for the Poland region
     *
     * App.initHelper('bleedsTreatmentReport');
     *
     */
    var uiHelperBleedsTreatmentReport = function(){
        // console.log('\n\tcalled uiHelperBleedsTreatmentReport\n');
        var bleedsTreatmentReportModal = $('#request-bleeds-treatment-form');
        var submitButtonClickable = false;

        // Initiate the Patient Search select box
        if ($('.patient-lookup').length) {
            $('.patient-lookup').select2({
                minimumInputLength: 1,
                ajax: {
                    url: function(){
                        console.log('Get Url');
                        return $(this).data('source');
                    },
                    processResults: function (data) {
                        console.log('Got Results', data);

                        var data = $.map(data.result, function (obj) {
                          obj.id = obj.id; // replace pk with your identifier
                          obj.text = obj.simpleLabel; // replace pk with your identifier
                          return obj;
                        });

                      // Tranforms the top-level key of the response object from 'items' to 'results'
                      return {
                        results: data
                      };
                    }
                }
            });
        }

        bleedsTreatmentReportModal.find('#submit-button-excel-bleeds-treatment').on('click', function(event){
            // Submitting as Excel
            // Update format field
            $('#bleedstreatmentreportformat').val('EXCEL');
            var form = $('#bleeds-treatment-report-generation');
            var validateURL = form.attr('action') + '/validate?';
            $.get(validateURL  + $(form).serialize(), function (data) {
                if (data === 'true') {
                    submitButtonClickable = true;
                    return;
                } else {
                    submitButtonClickable = false;
                    $('.bleeds-treatment-report-no-results').fadeIn();
                    $('#bleeds-treatment-request-block').removeClass('block-opt-refresh');
                }
            });
            // console.log('bleedsTreatmentReportModal --- click excel function', $('#bleedstreatmentreportformat').val());
            return;
        });

        bleedsTreatmentReportModal.find('#submit-button-pdf-bleeds-treatment').on('click', function(event){
            // Submitting as PDF
            // Update format field
            submitButtonClickable = true;        
            $('#bleedstreatmentreportformat').val('PDF');
            var form = $('#bleeds-treatment-report-generation');
            var validateURL = form.attr('action') + '/validate?';
            $.get(validateURL  + $(form).serialize(), function (data) {
                if (data === 'true') {
                    submitButtonClickable = true;
                    return;
                } else {
                    submitButtonClickable = false;
                    $('.bleeds-treatment-report-no-results').fadeIn();
                    $('#bleeds-treatment-request-block').removeClass('block-opt-refresh');
                }
            });
            // console.log('bleedsTreatmentReportModal --- click pdf function', $('#bleedstreatmentreportformat').val());
            return;
        });            

        bleedsTreatmentReportModal.find('#bleeds-treatment-report-generation').submit(function( event ) {
            // console.log('bleedsTreatmentReportModal --- submit function');
            event.preventDefault();
            bleedsTreatmentReportModal.find('#bleeds-treatment-request-block').addClass('block-opt-refresh');
            form = this;

            setTimeout(function() {
                if (submitButtonClickable) {
                    bleedsTreatmentReportModal.modal('hide');
                }
            }, 750);
            
            setTimeout( function () {
                if (submitButtonClickable) {
                    form.submit();
                }
            }, 1000);
            return;
        });                

        jQuery('#request-bleeds-treatment-form').on('hide.bs.modal', function (event) {
            // console.log('bleeding report --- hide modal function');
            var modal = $(this);
            modal.find('#bleeds-treatment-request-block').removeClass('block-opt-refresh');
            modal.find('.bleeds-treatment-report-no-results').hide();
        });
    };

    /*
     * Stock Usage Report Generation for the Poland region
     *
     * App.initHelper('stockUsageReport');
     *
     */
    var uiHelperStockUsageReport = function(){
        // console.log('\n\tcalled uiHelperStockUsageReport\n');
        var stockUsageModal = $('#request-stock-usage-form');
        var submitButtonClickable = false;

        stockUsageModal.find('#submit-button-excel-stock-usage').on('click', function(event){
            // Submitting as Excel
            // Update format field
            $('#stockreportformat').val('EXCEL');
            var form = $('#stock-usage-report-generation');
            var validateURL = form.attr('action') + '/validate?';
            $.get(validateURL  + $(form).serialize(), function (data) {
                if (data === 'true') {
                    submitButtonClickable = true;
                    // console.log('stockUsageModal --- click excel function');
                    return;
                } else {
                    submitButtonClickable = false;
                    $('.stock-usage-report-no-results').fadeIn();
                    $('#stock-usage-request-block').removeClass('block-opt-refresh');
                }
            });
            return;
        });

        stockUsageModal.find('#submit-button-pdf-stock-usage').on('click', function(event){
            // console.log('stockUsageModal --- click pdf function');
            // Submitting as PDF
            // Update format field
            submitButtonClickable = true;        
            $('#stockreportformat').val('PDF');
            var form = $('#stock-usage-report-generation');
            var validateURL = form.attr('action') + '/validate?';
            $.get(validateURL  + $(form).serialize(), function (data) {
                if (data === 'true') {
                    submitButtonClickable = true;
                    // console.log('stockUsageModal --- click excel function');
                    return;
                } else {
                    submitButtonClickable = false;
                    $('.stock-usage-report-no-results').fadeIn();
                    $('#stock-usage-request-block').removeClass('block-opt-refresh');
                }
            });
            return;
        });            

        stockUsageModal.find('#stock-usage-report-generation').submit(function( event ) {
            // console.log('stockUsageModal --- submit function');
            event.preventDefault();
            stockUsageModal.find('#stock-usage-request-block').addClass('block-opt-refresh');
            form = this;

            setTimeout(function() {
                if (submitButtonClickable) {
                    stockUsageModal.modal('hide');
                }
            }, 750);
            
            setTimeout( function () {
                if (submitButtonClickable) {
                    form.submit();
                }
            }, 1000);
            return;
        });                

        jQuery('#request-stock-usage-form').on('hide.bs.modal', function (event) {
            var modal = $(this);
            modal.find('#stock-usage-request-block').removeClass('block-opt-refresh');
            modal.find('.stock-usage-report-no-results').hide();
        });
    };

    /*
     * HTC Report Generation
     *
     * App.initHelper('htcReport');
     *
     */
    var uiHelperHTCReport = function(){
        var htcModal = jQuery('#generate-htc-report');

        htcModal.on('show.bs.modal', function (event) {
            htcModal.find('.js-select2').val(null).trigger("change");
        });

        htcModal.find( "#htcReportGeneration" ).submit(function( event ) {
            event.preventDefault();

            htcModal.find('#htcReportBlock').addClass('block-opt-refresh');
            form = this;

            var validateURL = $(form).attr('action') + '/validate?';
            $.get(validateURL  + $(form).serialize(), function (data) {
                console.log('Validated form and got response', data);

                if (data === "true") {
                    setTimeout( function () { 
                        htcModal.modal('hide');
                    }, 250);

                    setTimeout( function () { 
                        form.submit();
                    }, 500);
                } else{
                    htcModal.find('.no-results').fadeIn();
                    htcModal.find('#htcReportBlock').removeClass('block-opt-refresh');
                }
            });
            return false;
        });

        jQuery('#generate-htc-report').on('hide.bs.modal', function (event) {
            var modal = $(this);
            modal.find('#htcReportBlock').removeClass('block-opt-refresh');
            modal.find('.no-results').hide();
         });
    };

    /*
     * Conditionize, for more examples you can check out https://github.com/renvrant/conditionize.js
     *
     * App.initHelper('conditionize');
     *
     */
    var uiHelperConditionize = function(){
        // Init conditionize (with .conditional class)
        jQuery('.conditional').conditionize({
            hideJS: true
        });

    };

    /*
     * Edit Infusion Schedule Helper 
     *
     */
    var uiHelperInfusionSchedule = function(){

        var row, cell, label;
        var productsCount = $('.default-products .form-group ').length;

        $(".default-products")
            .on('select2:select', 'select[name*="[product]"]', function(selected) {
                // console.log('selected', selected);
                var uom = $(selected.params.data.element).data('uom');
                if (uom) {
                    $(this).parents('.form-group').find('span[data-replace="uom"]').text(' (' + uom + ')');
                }
            }).on('select2:unselect', 'select[name*="[product]"]', function(unselected) {
                // console.log('Unselected');
                $(this).parents('.form-group').find('span[data-replace="uom"]').text('');
            });


        console.warn('uiHelper Infusion Schedule');

        var showAppliesToFilters = function(){
            console.log('Show filters');

            $('.product-col-filter').show();
            $('.product-col-name').toggleClass('col-xs-4 col-xs-8');
        }

        var hideAppliesToFilters = function(){
            console.log('Hide filters');

            $('.product-col-filter').hide();
            $('.product-col-name').toggleClass('col-xs-4 col-xs-8');
        }

        // Enable the "Applies To" field for Weekly schedules
        $('select[name="schedule_details"]')
            .on('select2:select', function (selected) {
                console.log('selected', selected);
                if (selected.params.data.id === "Weekly") {
                    showAppliesToFilters();
                } else{
                    hideAppliesToFilters();
                }
            });

        if ($('select[name="schedule_details"]').val() !== "Weekly") {
            hideAppliesToFilters();
        }


        var addDefaultProductRow = function(){
            
            productsCount++;

            var hasAppliesTo = $('select[name="schedule_details"]').val() === "Weekly";

            // row = $('.default-products .block-content .form-group').first().clone();

            row = $('<div class="form-group">');

            // cell = $('.default-products .col-xs-4').first()

            // Build out Select Product Dropdown
            var productList = $('.default-products select[name$="[product]"]').first().clone().contents();
            var daysList = $('.default-products select[name$="[days][]"]').first().clone().contents().attr('selected',false);

            cell = $('<div class="product-col-name col-xs-4">').appendTo(row);

            if (!hasAppliesTo) {
                cell.toggleClass('col-xs-4 col-xs-8');
            }

            cell = $('<div class="form-material form-material-primary">').appendTo(cell);
            var productSelect = $('<select>', {
                name: 'default_product[' + productsCount + '][product]',
                class: 'form-control js-select2',
                'data-placeholder':'',
                'data-allow-clear':'true',
                'data-minimum-results-for-search':'20'
            //     required: true
            }).appendTo(cell);
            productList.appendTo(productSelect);

            productSelect.val($('.default-products select[name$="[product]"]').first().val()).trigger('change');

            var uom = $('.default-products select[name$="[product]"] :selected').first().data('uom');

            console.log('Set Default Product', uom);

            $('<label for="default_product[' + productsCount + '][product]">' + zb.trans.form.schedule.product + '</label>').appendTo(cell);


            // Build out default dosage column
            cell = $('<div class="product-col-dosage col-xs-3">').appendTo(row);

            cell = $('<div class="form-material form-material-primary">').appendTo(cell);

            input = $('<input>', {
                name: 'default_product[' + productsCount + '][dosage]',
                class: 'form-control',
                type: 'text'
            }).appendTo(cell);

            label = $('<label for="default_product[' + productsCount + '][dosage]">' + zb.trans.form.schedule.dosage + ' <span data-replace="uom">' + (uom ? '(' + uom + ')' : '') + '</span></label>');
            label.appendTo(cell);


            // Build out active days column
            cell = $('<div class="product-col-filter col-xs-4">').appendTo(row);

            if (!hasAppliesTo) {
                cell.hide();
            }
            
            cell = $('<div class="form-material form-material-primary">').appendTo(cell);
            var daysSelect = $('<select>', {
                name: 'default_product[' + productsCount + '][days][]',
                class: 'form-control js-select2',
                multiple: 'multiple',
                'data-placeholder':zb.trans.form.schedule.allDays,
                'data-allow-clear':'true',
                'data-minimum-results-for-search':'20',
            //     required: true
            }).appendTo(cell);
            daysList.appendTo(daysSelect);

            $('<label for="default_product[' + productsCount + '][days]">' + zb.trans.form.schedule.appliesTo + '</label>').appendTo(cell);

            // Create the Delete Row item
            cell =  $('<div class="col-xs-1 text-center">').appendTo(row);
            cell = $('<div class="form-material form-material-primary remove-product-button">').appendTo(cell);
            removeRow = $('<i class="fa fa-trash-o" style="cursor:pointer"></i>').appendTo(cell);

            // Add to form
            $('.default-products .row').before(row);

            uiHelperSelect2();

        }

        $(".default-products .add-product-button").on('click', function () {
            addDefaultProductRow();
        });

        $(".default-products").on('click', '.remove-product-button', function () {
            // Remove row or clear if it's the first one
            console.log('Remove row');
            if ($('.default-products .form-group').length > 1) {
                $(this).parents('.form-group').remove();
            } else{
                $('.default-products input').val(null);
                $(".default-products select").val([]).change();
            }
        });

        
    };

    /*
     * Mark All Patient Alerts as Read Confirmation Modal
     *
     * App.initHelper('markAlertsAsRead');
     *
     */
    var uiHelperMarkAlertsAsRead = function(){
        jQuery('#request-mark-alerts-as-read').on('show.bs.modal', function (event) {
            var modal = $(this);
            var button = $(event.relatedTarget) // Button that triggered the modal

            modal.on('hide.bs.modal', function (e) {
              modal.find('#submitMarkAllAsRead').unbind();
            });

            modal.find('#submitMarkAllAsRead').click(function (e) {
                $(this).prop('disabled', true);
                var action = modal.find('#requestMarkAlertsAsReadForm').attr('action');

                App.blocks('#' + modal.find('.block').attr('id'),'state_loading');
                return $.ajax({
                    url: action,
                    dataType:'json',
                    type:'post',
                    success: function(result) {
                        var alertsBlock = button.parents('.row');
                        alertsBlock.slideUp("slow", function() { alertsBlock.remove();});
                        App.blocks('#' + modal.find('.block').attr('id'),'state_loading');
                        modal.modal('hide');
                    },
                    error: function (error) {
                        $(this).prop('disabled', false);
                        console.warn(error);
                    }
                });
            });
        });
    };

    /*
     * Inventory Managment Dashboard Helper
     * Provide support for:
     * - Add New Inventory Modal
     * - Dynamic updates or the volume/quantity inputs
     * - Update Inventory Stock Level Modal
     * - Delete Inventory Confirmation Modal
     *
     */
    var uiHelperOrderManageInventory = function(){

        // ADD NEW INVENTORY MODAL
        // Update the Default Dosage UOM based on the selected product

        var productSelect = $('select[name="productLot[product_id]"]')[0].selectize;
        var quantity = $('[name="inventory[quantity]"]');
        var volume = $('[name="inventory[volume]"]');
        var vialVolume = $('[name="productLot[dosage]"]');
        var lotNumber = $('[name="productLot[vial_lot]"]');

        productSelect.on('change', function(value){
            var option = this.options[value];
            if (option) {
                var uom = option.uom;
                $('span[data-replace="uom"]').text(' (' + uom + ')');
            } else {
                $('span[data-replace="uom"]').text('');
            }
            // We may need to revalidate the lot number to check for a unique value
            if (lotNumber.val()) {
                lotNumber.valid();
            }
        });

        quantity.on('change', function(value){
            if (vialVolume.val()) {
                volume.val(vialVolume.val() * $(this).val()).valid();
            }
        });

        volume.on('change', function(value){
            if (vialVolume.val()) {
                quantity.val($(this).val() / vialVolume.val()).valid();
            }
        });

        vialVolume.on('change', function(value){
            if (quantity.val()) {
                volume.val(vialVolume.val() * $(this).val()).valid();
            }
        });

        // INVENTORY DELETION MODAL
        jQuery('#confirm-delete-inventory').on('show.bs.modal', function (event) {
            var modal = $(this);
            var button = $(event.relatedTarget) // Button that triggered the modal
            var row = button.parents('tr');
            var table = row.parents('.table');

            var id = row.data('id');
            var lot = button.data('lot');
            var product = button.data('product');
            var action = button.data('action');

            // Text replacement
            modal.find('[data-replace = "product"]').text(product);
            modal.find('[data-replace = "lot"]').text(lot);
            modal.find('p:contains(":product")').html(function () {
                return $(this).html().replace(':product', '<strong data-replace="product">' + product + '</strong>'); 
            });
            modal.find('p:contains(":lot")').html(function () {
                return $(this).html().replace(':lot', '<strong data-replace="lot">' + lot + '</strong>'); 
            });

            // Unbind submit action on removal
            modal.on('hide.bs.modal', function (e) {
              modal.find('#submitDelete').unbind();
            });

            modal.find('#submitDelete').click(function (e) {
                return $.ajax({
                    url: action,
                    data: {"_method":"delete"},
                    dataType:'json',
                    type:'post',
                    success: function(result) {
                        
                        if (button.parents('.table.dataTable').length) {
                            // If the action comes from the datatable, just remove the row with the datatable API 
                            $('#availableInventory.dataTable').DataTable().row('[data-id="' + id + '"]').remove().draw( false );
                        }
                        modal.modal('hide');

                        if ($('#availableInventory.dataTable').DataTable().rows().count() === 0) {
                            location.reload();
                        }
                    },
                    error: function (error) {
                        // alert('error');
                        console.warn(error);
                    }
                });
            });
        });

    };

    /*
     * Order Managment Dashboard Helper
     * Provide support for:
     * - Add New Order Modal:
     *  - Patient Search Lookup select box
     *  - Adding multiple products to an order via the Add Products table
     *  - Dynamic loading of Products / Lot Numbers based on current inputs
     *  - Dynamic updates or the volume/quantity inputs
     *  - Pre-fill of Patient input when opening the New Order modal from the patient table
     *  - Display of Dynamically loaded Patient Inventory details
     * - Cancel Order
     */

    var uiHelperOrderManageOrders = function(){

        var addOrderModal = $("#add-order-modal");
        var patientSearch = $("#addOrderBlock select[name='patient']");
        var productSelectRequest;
        var patientSelectRequest;

        $('#search-eta').datepicker({
            todayHighlight: true,
            language: $('html').attr('lang'),
        })
            .on('changeDate', function(e) {
              $('#activeOrders').DataTable().draw();
            })
            .on('clearDate', function(e) {
              $('#activeOrders').DataTable().draw();
            });

        $('#search-eta input[name="start"]').on('changeDate', function(e) {
            var $end = $(e.target).siblings('[name="end"]');
            if (moment($end.datepicker('getDate')).isSame(moment(e.date))) {
                $end.datepicker('show');
            }
        })

        // Initiate the Patient Search select box
        patientSearchSelectize = patientSearch.selectize({
            valueField: "id",
            labelField: "label",
            searchField: ["label", "id"],
            loadThrottle: 500,
            selectOnTab: true,
            create: false,
            persist: false,
            highlight: false,
            // score: function() { return function() { return 1; }; }, // only filtering is done server side
            load: function(query, callback) {
                var self = this;
                if (!query.length){
                    this.clearOptions();
                    return false;
                } 
                if (patientSelectRequest) {
                    console.warn('Patient Search Has request, aborting', patientSelectRequest);
                    patientSelectRequest.abort()
                }
                patientSelectRequest = $.ajax({
                    url: this.$input.data('source') + '/' + encodeURIComponent(query),
                    type: 'GET',
                    error: function() {
                        console.warn('Selectize load error');
                        callback();
                    },
                    success: function(res) {
                        console.info('Got Patient Search response', res.result.slice(0, 10));
                        self.clearOptions();
                        callback(res.result.slice(0, 10));
                    }
                });
            },
            onChange: function(value) {
                // Update the products field to display products
                // based on the selected user's default product
                if (!value.length){
                    $('.active-orders-info').removeClass('fadeIn').addClass('fadeOut');
                    return;
                };

                if (this.options[value].activeOrders > 0 && !addOrderModal.data('isEditing')) {
                    $('.active-orders-info').show().removeClass('fadeOut').addClass('fadeIn').find('.active-orders-label').text(this.options[value].activeOrdersLabel);
                } else {
                    $('.active-orders-info').removeClass('fadeIn').addClass('fadeOut');
                }
                var productSelect = $('.js-selectize-product');
                if (productSelect.length) {
                    var productSelectize = productSelect[0].selectize;
                    productSelectize.disable();
                    productSelectize.clearOptions();
                    productSelectize.load(function(callback) {
                        // productSelectRequest && productSelectRequest.abort();
                        productSelectRequest = $.ajax({
                            url: $('#productListTableProduct').data('source') + '/' + encodeURIComponent(patientSearch.val()),
                            error: function(error) {
                                console.warn('Error getting products from patient list change', error);
                                callback();
                            },
                            success: function(res, a) {
                                productSelectize.enable();
                                callback(res);
                            }
                        });
                    });
                } else{
                    console.log('Couldn\'t find  product select');
                }
            }
        });

        var xhr;
        var productsCount = 0;

        var addProductRow = function (loadExisting) {
            loadExisting = typeof loadExisting !== 'undefined' ? loadExisting : false;
            productsCount++;
            var $table = $('#productListTable tbody');
            var row, cell, el, productSelect, lotSelect, quantity, volume, removeRow;

            row = $('<tr>');

            // Create the Product Select Field
            cell =  $('<td class="form-group">').appendTo(row);
            cell = $('<div class="form-material form-material-primary">').appendTo(cell);
            productSelect = $('<select>', {
                name: 'products[' + productsCount + '][product]',
                class: 'form-control js-selectize-product',
                required: true
            }).appendTo(cell);

            // Create the Lot Number Select Field
            cell =  $('<td class="form-group">').appendTo(row);
            cell = $('<div class="form-material form-material-primary">').appendTo(cell);
            lotSelect = $('<select>', {
                name: 'products[' + productsCount + '][stockId]',
                class: 'form-control js-selectize-lot',
                required: true
            }).appendTo(cell);

            // Create the Quantity Numerical Input
            cell =  $('<td class="form-group">').appendTo(row);
            cell = $('<div class="form-material form-material-primary">').appendTo(cell);
            quantity = $('<input>', {
                type: 'number',
                name: 'products[' + productsCount + '][quantity]',
                class: 'form-control',
                required: true,
                step: 1,
                min: 1
            }).appendTo(cell);

            // Create the Volume Numerical Input
            cell =  $('<td class="form-group">').appendTo(row);
            cell = $('<div class="form-material form-material-primary">').appendTo(cell);
            volume = $('<input>', {
                type: 'number',
                name: 'products[' + productsCount + '][volume]',
                class: 'form-control',
                required: true,
                step: 1,
                min: 1
            }).appendTo(cell);

            // Create the Delete Row item
            cell =  $('<td class="form-group">').appendTo(row);
            cell = $('<div class="form-material form-material-primary">').appendTo(cell);
            removeRow = $('<i class="fa fa-trash-o" style="cursor:pointer"></i>').appendTo(cell);

            removeRow.on('click', function () {
                row.remove();
                if (!$('#productListTable tbody>tr').length) {
                    productsCount = 0;
                    addProductRow();
                }
            });

            // Initialise the Product Selection box
            // Values are loaded from the zb.products object
            productSelect.selectize({
                valueField: 'name',
                labelField: 'name',
                searchField: 'name',
                options: [],
                placeholder: $('#productListTableProduct').data('placeholder'),
                create: false,
                preload: true,
                load: function(query, callback) {
                    var self = this;
                    self.disable();
                    productSelectRequest && productSelectRequest.abort();
                    $.ajax({
                        url: $('#productListTableProduct').data('source') + '/' + encodeURIComponent(patientSearch.val()),
                        data: {
                            forOrderStock: loadExisting 
                        },
                        error: function(error) {
                            console.warn('Error loading initial product list', error);
                            // callback();
                        },
                        success: function(res) {
                            self.enable();
                            callback(res);
                        }
                    });
                },
                onChange: function(value) {
                    // Update the Lot Number field to display valid lot numbers for the
                    // selected product.
                    $(this.$input).valid();
                    if (!value.length) return;

                    var productSelectize = productSelect[0].selectize;
                    productSelect.data('vialVolume', productSelectize.options[productSelectize.getValue()].volume);
                    if (!value.length) return;
                    var $lotSelectize = lotSelect;
                    var lotSelectize = $lotSelectize[0].selectize;
                    lotSelectize.disable();
                    lotSelectize.clearOptions();
                    lotSelectize.load(function(callback) {
                        // xhr && xhr.abort();
                        xhr = $.ajax({
                            url: $('#productListTableLot').data('source') + '/' + encodeURIComponent(value),
                            data: {
                                forOrderStock: loadExisting 
                            },
                            success: function(results) {
                                console.log('Got it!', results);
                                
                                if (results && results.length === 1) {
                                    console.log('Setting lot selectize value', results[0], results[0].stockId);
                                    lotSelectize.addOption(results[0]);
                                    lotSelectize.setValue(results[0].stockId);
                                }

                                lotSelectize.enable();
                                callback(results);
                            },
                            error: function(e) {
                                console.warn('Error getting lot numbers!', e);
                                callback();
                            }
                        })
                    });
                }
            });

            // Initialise the Lot Selection
            // Values are loaded from the onChange event of the 
            lotSelect.selectize({
                'valueField': 'stockId',
                'labelField': 'lot',
                'searchField': ['name'],
                'create': false,
                placeholder: $('#productListTableLot').data('placeholder'),
                onChange: function (value) {
                    $(this.$input).valid();
                    if (value && this.options[value]) {
                        quantity.attr('max', this.options[value].available);
                        quantity.attr('data-original-title', this.options[value].availableTitle);
                        volume.attr('max', this.options[value].volumeAvailable);
                        volume.attr('data-original-title', this.options[value].volumeAvailableTitle);
                        console.log('Set Lot Number', value, 'with', this.options[value].availableTitle);
                    } else {
                        quantity.removeAttr('max');
                        quantity.removeAttr('data-original-title');
                        volume.removeAttr('max');
                        volume.removeAttr('data-original-title');
                    }

                }
            });

            // Disable the Lot Selection on init, as we require the product to be selected first.
            lotSelect[0].selectize.disable();

            quantity.tooltip({
                animation: false,
                trigger: 'hover focus',
                placement: 'bottom'
            });

            // Update the Volume count when the quantity changes
            quantity.on('change', function(value){
                vialVolume = productSelect.data().vialVolume;

                if (vialVolume) {
                    volume.val(vialVolume * $(this).val()).valid();
                }
            });

            volume.tooltip({
                animation: false,
                trigger: 'hover focus',
                placement: 'bottom'
            });

            // Update the Quantity count when the volume changes
            volume.on('change', function(value){
                vialVolume = productSelect.data().vialVolume;
                if (vialVolume) {
                    quantity.val($(this).val() / vialVolume).valid();
                }
            });

            row.appendTo($table);

        }
        
        $("#addOrderBlock .add-product-button").on('click', function () {
            addProductRow();
        });

        // If we've opened the Add Order Modal from a New Order button in the
        // Patients Table, or an Edit Order button, prefill the patient field
        // with the selected data / user
        jQuery('#add-order-modal').on('show.bs.modal', function (event) {

            // If the show event is returning from the datepicker,
            // we don't need to continue...
            if ($(event.target).hasClass('js-datepicker')) {
                return false;
            }

            var modal = $(this);
            var form = modal.find('form');

            form.attr('action', form.data('action'));

            var button = $(event.relatedTarget);
            var submitButton = modal.find('button[type=submit]');
            var patient = button.data('user');
            var order = button.data('edit');
            var clearForm = button.data('clear');
            var selectize = patientSearchSelectize[0].selectize;

            $('#productListTable tbody').html('');
            productsCount = 0;

            // Setup the modal title/buttons to refer to a new order
            modal.find('.block-title').text(modal.find('.block-title').data('add'));
            submitButton.removeClass('btn-danger').addClass('btn-primary').find('span').text(submitButton.find('span').data('add'));
            submitButton.find('i.fa').removeClass('fa-warning').addClass('fa-plus');

            if (!order && !patient) {
                addProductRow();
            }

            // Clear the form
            form.trigger("reset");
            form.validate().resetForm();
            jQuery('#add-order-modal').find('.has-error').removeClass('has-error');
            selectize.enable();
            selectize.clearOptions();
            modal.data('isEditing', false);
            modal.data('hasPatient', false);
            modal.find('.active-orders-info').removeClass('fadeIn fadeOut').hide();
            modal.find('input[name="eta"]').datepicker('setStartDate', moment().format('YYYY-MM-DD'));
            modal.find('.js-selectize.selectized').each(function(){
                if (this.selectize) {
                    this.selectize.clear();
                }
            });

            // If we've defined a patient to save the order against, load the data
            // for the patient and prefill the field
            if (patient) {
                modal.data('hasPatient', true);
                // modal.find('#addOrderBlock').addClass('block-opt-refresh');
                productSelectRequest && productSelectRequest.abort();
                $.ajax({
                    url: patientSearch.data('source') + '/' + encodeURIComponent(patient),
                    type: 'GET',
                    error: function(error) {
                        console.warn('Error loading data', error);
                        // modal.find('#addOrderBlock').removeClass('block-opt-refresh');
                    },
                    success: function(res) {
                        var resPatient = _.find(res.result,{id:patient});
                        if (patientSelectRequest) {
                            patientSelectRequest.abort();
                        }
                        // if (productSelectRequest) {
                        //     productSelectRequest.abort();
                        // }
                        selectize.clearOptions();
                        selectize.addOption(resPatient);
                        selectize.setValue(patient);

                        if (!order) {
                            modal.find('#addOrderBlock').removeClass('block-opt-refresh');
                            addProductRow();
                        }
                    }
                });
            };

            // If we've defined an order to edit, load the data for the order and
            // fill the form
            if (order) {
                modal.data('isEditing', true);
                // Setup the modal window for the order.
                modal.find('#addOrderBlock').addClass('block-opt-refresh');
                form.attr('action', button.data('action'));

                // Update the Title and buttons to refer to editing the order
                modal.find('.block-title').text(modal.find('.block-title').data('edit'));
                submitButton.addClass('btn-danger').removeClass('btn-primary').find('span').text(submitButton.find('span').data('edit'));
                submitButton.find('i.fa').removeClass('fa-plus').addClass('fa-warning');

                // Call the Load Order function once the patient has been updated
                var loadOrder = function (patient) {
                    if (!patient.length) return;

                    // Remove the listener to ensure we only fire once
                    this.off('change', loadOrder);

                    // Disable the patient select, as the user cannot be changed on an existing order
                    selectize.disable();

                    // Load the Order data
                    $.ajax({
                        url: button.data('source'),
                        type: 'GET',
                        error: function() {
                            modal.find('#addOrderBlock').removeClass('block-opt-refresh');
                        },
                        success: function(data) {
                            var order = data.data; 

                            // Set the ETA
                            var eta = moment.utc(order.eta.date).local().format('YYYY-MM-DD');

                            // If ETA is in the past, we need to allow the datepicker to include it
                            if (moment(eta).isBefore(moment())) {
                                modal.find('input[name="eta"]').datepicker('setStartDate', eta).datepicker('update', eta);
                            } else{
                                modal.find('input[name="eta"]').datepicker('update', eta);
                            }

                            // Set the Courier
                            modal.find('select[name="courier"]')[0].selectize.setValue(order.courier.data.id);

                            // Load the Products for the order as promises to track progress
                            var promises = [];
                            _.forEach(order.stock.data, function(product, key){
                                addProductRow(product.id);
                                var $productRow = $('#productListTable tbody tr').eq(key);
                                var productSelect = $productRow.find('select.js-selectize-product')[0].selectize;
                                var lotSelect = $productRow.find('select.js-selectize-lot')[0].selectize;

                                var loadProductPromise = $.Deferred();
                                var loadLotPromise = $.Deferred();

                                var loadProduct = function(data){
                                    this.setValue(product.productSKU);
                                    this.enable();
                                    loadProductPromise.resolve('true');
                                    this.off('load', loadProduct);
                                };

                                var loadLot = function(data){
                                    var option = _.find(this.options, {lot:product.vial});
                                    if (option) {
                                        this.setValue(option.stockId);
                                };
                                    loadLotPromise.resolve('true');
                                    this.off('load', loadLot);
                                };

                                productSelect.on('load', loadProduct);
                                lotSelect.on('load', loadLot);

                                promises.push(loadProductPromise);
                                promises.push(loadLotPromise);

                                $productRow.find('input[name*=quantity]').val(product.quantity);
                                $productRow.find('input[name*=volume]').val(product.totalVolume);
                            })

                            // Once all product information is available, clear the loader
                            $.when.apply($,promises).done(function () {
                                modal.find('#addOrderBlock').removeClass('block-opt-refresh');
                            });
                        }
                    });
                }
                selectize.on('change', loadOrder);
            } // End If Order

        });

        // On click of the View Inventory button for a patient, dynamically
        // load the patient's inventory table from the defined URL, then
        // open the modal window.
        $('#allPatients tbody').on('click', '.patient-inventory-btn', function(event){
            var patientInventory = $(event.currentTarget).data('source');
            var modal = jQuery('#patient-inventory-modal');
            var blockContent = modal.find('#patientInventoryBlock');

            $(event.currentTarget).addClass('loading');

            blockContent.load(patientInventory, function () {
                $(event.currentTarget).removeClass('loading');
                modal.modal('show');
            });
        });

        // ORDER RESCHEDULE MODAL
        jQuery('#reschedule-order-modal').on('show.bs.modal', function (event) {
            // If the show event is returning from the datepicker,
            // we don't need to continue...
            if ($(event.target).hasClass('js-datepicker')) {
                return false;
            }

            var modal = $(this);
            var button = $(event.relatedTarget) // Button that triggered the modal
            var row = button.parents('tr');
            var table = row.parents('.table');
            var etaInput = modal.find('input[name="eta"]');

            var id = row.data('id');
            var action = button.data('action');

            etaInput.datepicker('setStartDate', moment().format('YYYY-MM-DD'));

            // Set the ETA
            var eta = moment.utc(button.data('eta')).format('YYYY-MM-DD');

            // If ETA is in the past, we need to allow the datepicker to include it
            if (moment(eta).isBefore(moment())) {
                etaInput.datepicker('setStartDate', eta).datepicker('update', eta);
            } else{
                etaInput.datepicker('update', eta);
            }

            var form = modal.find('form');
            form.attr('action', action);
        });

        // ORDER CANCELLATION MODAL
        jQuery('#confirm-cancel-order').on('show.bs.modal', function (event) {
            var modal = $(this);
            var button = $(event.relatedTarget) // Button that triggered the modal
            var row = button.parents('tr');
            var table = row.parents('.table');

            var id = row.data('id');
            var action = button.data('action');

            // Unbind submit action on removal
            modal.on('hide.bs.modal', function (e) {
              modal.find('#submitCancellation').unbind();
            });

            modal.find('#submitCancellation').click(function (e) {
                return $.ajax({
                    url: action,
                    dataType:'json',
                    type:'post',
                    success: function(result) {
                        modal.modal('hide');
                        location.reload();
                    },
                    error: function (error) {
                        // alert('error');
                        console.warn(error);
                    }
                });
            });
        });
        jQuery('#confirm-remove-order').on('show.bs.modal', function (event) {
            var modal = $(this);
            var button = $(event.relatedTarget) // Button that triggered the modal
            var row = button.parents('tr');
            var table = row.parents('.table');

            var id = row.data('id');
            var action = button.data('action');

            // Unbind submit action on removal
            modal.on('hide.bs.modal', function (e) {
              modal.find('#submitRemoval').unbind();
            });

            modal.find('#submitRemoval').click(function (e) {
                return $.ajax({
                    url: action,
                    dataType:'json',
                    type:'post',
                    success: function(result) {
                        if (button.parents('.table.dataTable').length) {
                            // If the action comes from the datatable, just remove the row with the datatable API 
                            table.DataTable().row('[data-id="' + id + '"]').remove().draw( false );
                        }
                        modal.modal('hide');

                        if (table.DataTable().rows().count() === 0) {
                            location.reload();
                        }
                    },
                    error: function (error) {
                        // alert('error');
                        console.warn(error);
                    }
                });
            });
        });
    };


    /*
     * Pharmacist Inventory Managment Dashboard Helper
     * Provide support for:
     *  - Assign Inventory Modal:
     *  - Patient Search Lookup select box
     *  - Adding multiple products to an inventory via the Add Products table
     *  - Dynamic loading of Products based on current inputs
     *  - Dynamic updates or the volume/quantity inputs
     *  - Pre-fill of Patient input when opening the New Inventory modal from the patient table
     *  - Display of Dynamically loaded Patient Inventory details
     *  - Cancel Order
     */

    var uiHelperPharmacistManageInventory = function(){

        console.log('load pharmacistManageInventory');

        var assignInventoryModal = $("#pharmacist-assign-inventory-modal");
        var patientSearch = $("#pharmacist-assign-inventory-block select[name='patient']");
        var productSelectRequest;
        var patientSelectRequest;
        var assignInventoryFlag;
        var productsCount = 0;

        // Initiate the Patient Search select box
        patientSearchSelectize = patientSearch.selectize({
            valueField: "id",
            labelField: "label",
            searchField: ["label", "id"],
            loadThrottle: 500,
            selectOnTab: true,
            create: false,
            persist: false,
            highlight: false,
            // score: function() { return function() { return 1; }; }, // only filtering is done server side
            load: function(query, callback) {
                var self = this;
                if (!query.length){
                    this.clearOptions();
                    return false;
                } 
                if (patientSelectRequest) {
                    console.warn('Patient Search Has request, aborting', patientSelectRequest);
                    patientSelectRequest.abort()
                }
                patientSelectRequest = $.ajax({
                    url: this.$input.data('source') + '/' + encodeURIComponent(query),
                    type: 'GET',
                    error: function() {
                        console.warn('Selectize load error');
                        callback();
                    },
                    success: function(res) {
                        console.info('Got Patient Search response', res.result.slice(0, 10));
                        self.clearOptions();
                        callback(res.result.slice(0, 10));
                    }
                });
            },
            onChange: function(value) {
                // Update the products field to display products
                // based on the selected user's default product
                if (!value.length){
                    $('.active-orders-info').removeClass('fadeIn').addClass('fadeOut');
                    return;
                };

                if (this.options[value].activeOrders > 0 && !assignInventoryModal.data('isEditing')) {
                    $('.active-orders-info').show().removeClass('fadeOut').addClass('fadeIn').find('.active-orders-label').text(this.options[value].activeOrdersLabel);
                } else {
                    $('.active-orders-info').removeClass('fadeIn').addClass('fadeOut');
                }

                if (!assignInventoryFlag) {
                    if (productSelect.length) {
                        var productSelectize = productSelect[0].selectize;
                        productSelectize.disable();
                        // productSelectize.clearOptions();
                        productSelectize.load(function(callback) {
                            // productSelectRequest && productSelectRequest.abort();
                            productSelectRequest = $.ajax({
                                url: $('#productListTableProduct').data('source') + '/' + encodeURIComponent(patientSearch.val()),
                                error: function(error) {
                                    console.warn('Error getting products from patient list change', error);
                                    callback();
                                },
                                success: function(res, a) {
                                    productSelectize.enable();
                                    callback(res);
                                }
                            });
                        });
                    } else{
                        console.log('Couldn\'t find  product select');
                    }                    
                }
            }
        });

        var xhr;

        var addProductRow = function (loadExisting) {
            loadExisting = typeof loadExisting !== 'undefined' ? loadExisting : false;
            productsCount++;
            console.log('productsCount', productsCount);

            var $table = $('#productListTable tbody');
            var row, cell, productSelect, removeRow, vialVolumeSelect, quantity, volume, paidSelect;

            row = $('<tr>');

            // Create the Product Select Field
            // var productList = $('#product-list').data('products').split(',');
            cell = $('<td class="form-group">').appendTo(row);
            cell = $('<div class="form-material form-material-primary">').appendTo(cell);
            productSelect = $('<select>', {
                name: 'products[' + productsCount + '][product]',
                class: 'form-control js-selectize-product',
                required: true
            }).appendTo(cell);

            // Create the Vial Volume Numerical Field
            cell =  $('<td class="form-group">').appendTo(row);
            cell = $('<div class="form-material form-material-primary">').appendTo(cell);
            vialVolumeSelect = $('<input>', {
                type: 'number',
                name: 'products[' + productsCount + '][dosage]',
                class: 'form-control js-selectize-vialVolume',
                required: true
            }).appendTo(cell);

            // Create the Quantity Numerical Input
            cell =  $('<td class="form-group">').appendTo(row);
            cell = $('<div class="form-material form-material-primary">').appendTo(cell);
            quantity = $('<input>', {
                type: 'number',
                name: 'products[' + productsCount + '][quantity]',
                class: 'form-control',
                required: true,
                step: 1,
                min: 1
            }).appendTo(cell);

            // Create the Volume Numerical Input
            // cell =  $('<td class="form-group">').appendTo(row);
            // cell = $('<div class="form-material form-material-primary">').appendTo(cell);
            // volume = $('<input>', {
            //     type: 'text',
            //     name: 'products[' + productsCount + '][volume]',
            //     class: 'form-control',
            //     required: true,
            // }).appendTo(cell);

            // Create the Paid Status Input
            cell =  $('<td class="form-group">').appendTo(row);
            cell = $('<div class="form-material form-material-primary">').appendTo(cell);
            paidSelect = $('<select>', {
                type: 'text',
                name: 'products[' + productsCount + '][paid]',
                class: 'form-control js-selectize-paid',
                required: true,
            }).appendTo(cell);

            // Create the Delete Row item
            cell =  $('<td class="form-group">').appendTo(row);
            cell = $('<div class="form-material form-material-primary">').appendTo(cell);
            removeRow = $('<i class="fa fa-trash-o" style="cursor:pointer"></i>').appendTo(cell);

            removeRow.on('click', function () {
                row.remove();
                if (!$('#productListTable tbody>tr').length) {
                    productsCount = 0;
                    addProductRow();
                }
            });

            // Initialise the Product Selection box
            // Values are loaded from the zb.products object
            if (!assignInventoryFlag) {
                productSelect.selectize({
                    valueField: 'name',
                    labelField: 'name',
                    searchField: 'name',
                    options: [],
                    placeholder: $('#productListTableProduct').data('placeholder'),
                    create: false,
                    preload: true,
                    load: function(query, callback) {
                        var self = this;
                        self.disable();
                        productSelectRequest && productSelectRequest.abort();
                        $.ajax({
                            url: $('#productListTableProduct').data('source') + '/' + encodeURIComponent(patientSearch.val()),
                            data: {
                                forOrderStock: loadExisting 
                            },
                            error: function(error) {
                                console.warn('Error loading initial product list', error);
                                // callback();
                            },
                            success: function(res) {
                                self.enable();
                                callback(res);
                            }
                        });
                    },
                    onChange: function(value) {
                        // Update the Lot Number field to display valid lot numbers for the
                        // selected product.
                        $(this.$input).valid();
                        // var productSelectize = productSelect[0].selectize;
                        // productSelect.data('vialVolume', productSelectize.options[productSelectize.getValue()].volume);
                        if (!value.length) return;
                    }
                });
            } else {
                var productOptions = $('#productListTableProduct').data('source');
                console.log('when assign inventory', productOptions);
                productSelect.selectize({
                    valueField: 'id',
                    labelField: 'name',
                    searchField: 'name',
                    options: productOptions,
                    onChange: function(value) {
                        var selectedProduct = _.filter(productOptions, function(o) {
                            return o.id === parseInt(value); 
                        })[0];
                        console.log('product id', value, 'selectedProduct', selectedProduct);

                        var vialVolume = {};
                        var index = 1;
                        var vialVolumeOptions = [];
                        _.each(selectedProduct.vialVolumeArray, function(v) {
                            vialVolume = {
                                id: index,
                                value: Number(v),
                                name: Number(v).toString() + ' IU'
                            };
                            index += 1;
                            vialVolumeOptions.push(vialVolume);
                        });
                        console.log('vialVolumeOptions', vialVolumeOptions);

                        vialVolumeSelect.selectize({
                            valueField: 'value',
                            labelField: 'name',
                            searchField: 'name',
                            maxItems: 1
                        });
                        // Prefill Vial Volume
                        var vialVolumeSelectize = vialVolumeSelect[0].selectize;
                        vialVolumeSelectize.clear();
                        vialVolumeSelectize.clearOptions();
                        vialVolumeSelectize.load(function(callback) {
                            callback(vialVolumeOptions);
                            this.setValue(1);
                        });
                    }
                });

                // var vialVolumeOptions = [
                //     {
                //         id: 1,
                //         value: 250,
                //         name: '250 IU'
                //     },
                //     {
                //         id: 2,
                //         value: 500,
                //         name: '500 IU'
                //     },
                //     {
                //         id: 3,
                //         value: 1000,
                //         name: '1000 IU'
                //     },
                //     {
                //         id: 4,
                //         value: 1500,
                //         name: '1500 IU'
                //     },
                // ];

                var paidStatusOptions = [
                    {
                        id: 1,
                        value: true,
                        name: zb.assignPatientInventoryModal.trans.paid.true
                    },
                    {
                        id: 2,
                        value: false,
                        name: zb.assignPatientInventoryModal.trans.paid.false
                    }
                ];
                console.log('paidStatusOptions', paidStatusOptions);

                paidSelect.selectize({
                    valueField: 'value',
                    labelField: 'name',
                    searchField: 'name',
                    options: paidStatusOptions
                });

                // Prefill Paid status
                var paidSelectize = paidSelect[0].selectize;
                paidSelectize.setValue(1);
            }

            quantity.tooltip({
                animation: false,
                trigger: 'hover focus',
                placement: 'bottom'
            });

            // Update the Volume count when the quantity changes
            // quantity.on('change', function(value){
            //     if (vialVolumeSelect && vialVolumeSelect.val()) {
            //         volume.val(vialVolumeSelect.val() * $(this).val()).valid();
            //     }
            // });

            // volume.tooltip({
            //     animation: false,
            //     trigger: 'hover focus',
            //     placement: 'bottom'
            // });

            // Update the Quantity count when the volume changes
            // volume.on('change', function(value){
            //     if (vialVolumeSelect && vialVolumeSelect.val()) {
            //         quantity.val($(this).val() / vialVolumeSelect.val()).valid();
            //     }
            // });

            row.appendTo($table);
        }

        $("#pharmacist-assign-inventory-block .add-product-button").on('click', function () {
            console.log('Add Product button clicked');
            addProductRow();
        });

        // ASSIGN INVENTORY MODAL
        jQuery('#pharmacist-assign-inventory-modal').on('show.bs.modal', function (event) {

            console.log('assign inventory modal is open');
            assignInventoryFlag = true;
            productsCount = 0;

            // If the show event is returning from the datepicker,
            // we don't need to continue...
            if ($(event.target).hasClass('js-datepicker')) {
                return false;
            }

            var modal = $(this);
            var form = modal.find('form');

            form.attr('action', form.data('action'));

            var button = $(event.relatedTarget);
            var submitButton = modal.find('button[type=submit]');
            var patientSelectize = patientSearchSelectize[0].selectize;

            $('#productListTable tbody').html('');
            productsCount = 0;
            addProductRow();

            // Clear the form
            form.trigger("reset");
            form.validate().resetForm();
            modal.find('.has-error').removeClass('has-error');
            patientSelectize.enable();
            patientSelectize.clearOptions();
            modal.find('.js-selectize.selectized').each(function(){
                if (this.selectize) {
                    this.selectize.clear();
                }
            });

            // Unbind submit action on removal
            modal.on('hide.bs.modal', function (e) {
                modal.find('#submitCancelOrder').unbind();
                assignInventoryFlag = false;
                // reset product count to 0 when close the modal
                productsCount = 0;
            });
        });

        // INVENTORY DELETION MODAL
        jQuery('#confirm-delete-inventory').on('show.bs.modal', function (event) {
            console.log('delete inventory modal is open');
            assignInventoryFlag = false;

            var modal = $(this);
            var button = $(event.relatedTarget) // Button that triggered the modal
            var row = button.parents('tr');
            var table = row.parents('.table');

            var id = row.data('id');
            var lot = button.data('lot');
            var product = button.data('product');
            var action = button.data('action');

            // Text replacement
            modal.find('[data-replace = "product"]').text(product);
            modal.find('[data-replace = "lot"]').text(lot);
            modal.find('p:contains(":product")').html(function () {
                return $(this).html().replace(':product', '<strong data-replace="product">' + product + '</strong>'); 
            });
            modal.find('p:contains(":lot")').html(function () {
                return $(this).html().replace(':lot', '<strong data-replace="lot">' + lot + '</strong>'); 
            });

            // Unbind submit action on removal
            modal.on('hide.bs.modal', function (e) {
              modal.find('#submitDelete').unbind();
            });

            modal.find('#submitDelete').click(function (e) {
                return $.ajax({
                    url: action,
                    data: {"_method":"delete"},
                    dataType:'json',
                    type:'post',
                    success: function(result) {
                        
                        if (button.parents('.table.dataTable').length) {
                            // If the action comes from the datatable, just remove the row with the datatable API
                            $('#availableInventory.dataTable').DataTable().row('[data-id="' + id + '"]').remove().draw( false );
                            location.reload();
                        }
                        modal.modal('hide');

                        if ($('#availableInventory.dataTable').DataTable().rows().count() === 0) {
                            location.reload();
                        }
                    },
                    error: function (error) {
                        // alert('error');
                        console.warn(error);
                    }
                });
            });
        });

        // UPDATE INVENTORY TOTAL / DELIVERED VOLUME MODAL
        jQuery('#update-pharmacist-inventory-modal').on('show.bs.modal', function (event) {
            console.log('update inventory modal is open');
            assignInventoryFlag = false;

            // Close any existing modals
            var currentModal = $('.modal.in');
            currentModal.modal('hide');

            // Initiate the modal, get data for the selected item
            var modal = $(this);
            var button = $(event.relatedTarget) // Button that triggered the modal
            var row = button.parents('tr');
            if (row.hasClass('child')) {
                row = row.prev('tr');
            }

            var table = row.parents('.table');
            var previewTable = modal.find('#stockPreviewTable');
            var action = button.data('action');

            modal.find('form').attr('action', action).validate().resetForm();

            // Extract Product data from the row
            var id = row.data('id');
            console.log('picked inventory id', id);
            var product = row.find('td[data-content="name"]').text();
            var patient = table.data('name');
            var vialVolume = row.find('td[data-content="name"]').data('volume');
            var uom = row.find('td[data-content="name"]').data('uom');

            // Update preview table with product data info
            modal.find('[data-replace = "product"]').text(product);
            modal.find('[data-replace = "patient"]').text(patient);

            // Extract stock level data from the row and populate the preview table
            var totalVolumeCell = row.find('td[data-content="totalQuantity"]');
            totalVolumeCell = previewTable.find('[data-replace = "totalVolume"]').html(totalVolumeCell.html());
            
            var consumedQuantityCell = row.find('td[data-content="consumedQuantity"]');
            consumedQuantityCell = previewTable.find('[data-replace = "delivered"]').html(consumedQuantityCell.html());

            var inStockCell = row.find('td[data-content="availableQuantity"]');
            inStockCell = previewTable.find('[data-replace = "available"]').html(inStockCell.html());
            inStockCell.removeClass('bg-danger text-white');

            // Get individual content elements in the preview row for later updating
            var totalBoxesContent = previewTable.find('[data-content="totalBoxes"]');
            var totalVolumeContent = previewTable.find('[data-content="totalVolume"]');
            var consumedBoxesContent = previewTable.find('[data-content="consumedBoxes"]');
            var consumedVolumeContent = previewTable.find('[data-content="consumedVolume"]');
            var availableBoxesContent = previewTable.find('[data-content="availableBoxes"]');
            var availableVolumeContent = previewTable.find('[data-content="availableVolume"]');

            // Extract Total Volume data points from the row data
            var quantity = row.find('td[data-content="totalQuantity"] span[data-content="totalBoxes"]').data('value');
            var volume = quantity * vialVolume;

            var orderedQuantity = row.find('td[data-content="consumedQuantity"] span[data-content="consumedBoxes"]').data('value');
            var orderedVolume = orderedQuantity * vialVolume;

            // Initiate and populate the input fields
            var quantityInput = modal.find('[name="totalInventory[quantity]"]');
            var volumeInput = modal.find('[name="totalInventory[volume]"]');
            var orderedQuantityInput = modal.find('[name="orderedInventory[quantity]"]');
            var orderedVolumeInput = modal.find('[name="orderedInventory[volume]"]');
            
            quantityInput.val(quantity);
            volumeInput.val(volume);
            orderedQuantityInput.val(orderedQuantity);
            orderedVolumeInput.val(orderedVolume);
            volumeInput.attr('step', vialVolume);
            orderedVolumeInput.attr('step', vialVolume);

            // Handle toggling between "Total Volume" and "Ordered Volume" in the modal window
            var setSectionOrdered = function () {
                consumedQuantityCell.addClass('bg-success text-white');
                totalVolumeCell.removeClass('bg-success text-white');
                modal.find('.form-group.ordered-inventory').show();
                modal.find('.form-group.total-inventory').hide();
            }

            var setSectionTotal = function () {
                totalVolumeCell.addClass('bg-success text-white');
                consumedQuantityCell.removeClass('bg-success text-white');
                modal.find('.form-group.total-inventory').show();
                modal.find('.form-group.ordered-inventory').hide();
            }

            // Load default view 
            if (button.data('section') === "orderedVolume") {
                setSectionOrdered();
            } else {
                setSectionTotal();
            }

            // Load view on click of Preview Table
            previewTable.on('click', '[data-replace="totalVolume"]', function (e) {
                setSectionTotal();
            });
            previewTable.on('click', '[data-replace="delivered"]', function (e) {
                setSectionOrdered();
            });

            // Update the row preview and validation rules when an input value changes
            var updateRowPreview = function () {
                // Determine available quantities
                availableQuantity = quantity - orderedQuantity;
                availableVolume = availableQuantity * vialVolume;

                // Update the preview table
                totalBoxesContent.text(quantity.toLocaleString() + ' ' + totalBoxesContent.data('unit'));
                totalVolumeContent.text(volume.toLocaleString() + ' ' + uom);
                consumedBoxesContent.text(orderedQuantity.toLocaleString() + ' ' + consumedBoxesContent.data('unit'));
                consumedVolumeContent.text(orderedVolume.toLocaleString() + ' ' + uom);
                availableBoxesContent.text(availableQuantity.toLocaleString() + ' ' + availableBoxesContent.data('unit'));
                availableVolumeContent.text(availableVolume.toLocaleString() + ' ' + uom);

                // Highlight the preview table on error (eg. available qty of less than 0)
                if (availableQuantity < 0) {
                    inStockCell.addClass('bg-danger text-white');
                } else {
                    inStockCell.removeClass('bg-danger text-white');
                }

                // Update input validation min/max values
                quantityInput.attr('min', orderedQuantity);
                orderedQuantityInput.attr('max', quantity);

                // Re-validate form with new values
                modal.find('form').valid();
            };

            // Detect change events on inputs
            quantityInput.on('change', function(value){
                quantity = parseInt(quantityInput.val());
                volume = quantity * vialVolume;
                volumeInput.val(volume);
                updateRowPreview();
            });

            volumeInput.on('change', function(value){
                volume = parseInt(volumeInput.val());
                quantity = volume / vialVolume;
                quantityInput.val(quantity);
                updateRowPreview();
            });

            orderedQuantityInput.on('change', function(value){
                orderedQuantity = parseInt(orderedQuantityInput.val());
                orderedVolume = orderedQuantity * vialVolume;
                orderedVolumeInput.val(orderedVolume);
                updateRowPreview();
            });

            orderedVolumeInput.on('change', function(value){
                orderedVolume = parseInt(orderedVolumeInput.val());
                orderedQuantity = orderedVolume / vialVolume;
                orderedQuantityInput.val(orderedQuantity);
                updateRowPreview();
            });

            // Unbind actions on removal
            modal.on('hide.bs.modal', function (e) {
              quantityInput.unbind();
              volumeInput.unbind();
              orderedQuantityInput.unbind();
              orderedVolumeInput.unbind();
              previewTable.unbind();

              // If we arrived here from a modal, show it again.
              currentModal.modal('show');
            });
        });

        // UPDATE INVENTORY PAID STATUS MODAL
        jQuery('#update-inventory-paid').on('show.bs.modal', function (event) {
            console.log('update inventory paid status modal is open');
            assignInventoryFlag = false;

            var modal = $(this);
            var button = $(event.relatedTarget) // Button that triggered the modal
            var row = button.parents('tr');
            var table = row.parents('.table');

            var id = row.data('id');
            var action = button.data('action');

            // Unbind submit action on removal
            modal.on('hide.bs.modal', function (e) {
              modal.find('#submit-update-paid-status').unbind();
            });

            App.blocks('#' + modal.find('.block').attr('id'),'state_loading');
            modal.find('#submit-update-paid-status').click(function (e) {
                return $.ajax({
                    url: action,
                    type:'post',
                    success: function(result) {
                        // console.log('Update patient inventory paid status sucessfully', result);
                        App.blocks('#' + modal.find('.block').attr('id'),'state_normal');
                        modal.modal('hide');
                        location.reload();
                    },
                    error: function (error) {
                        // alert('error');
                        App.blocks('#' + modal.find('.block').attr('id'),'state_normal');
                        console.warn(error);
                    }
                });
            });
        });
    };

    // Input available delivery weekdays array
    // Convert database available_delivery_weekdays weekday array
    // which is following isoWeekday: starting from 1 (Monday) to 7 (Sunday)
    // to bootstrap datepicker daysOfWeekDisabled weekday array
    // which is starting from 0 (Sunday) to 6 (Saturday)
    // https://bootstrap-datepicker.readthedocs.io/en/latest/options.html#daysofweekdisabled
    // Return the difference array, aka unavailable delivery weekdays array
    function convertWeekdays(weekdaysArray) {
        var resultArray = [];
        var completeWeekdays = [0, 1, 2, 3, 4, 5, 6];

        _.each(weekdaysArray, function(day) {
            if (day === 7) {
                resultArray.push(0);
            } else {
                resultArray.push(day);
            }
        });

        return _.difference(completeWeekdays, resultArray);
    }

    var uiHelperPharmacistManageOrder = function(){
        console.log('load pharmacistManageOrder');

        var operateOrderModal = $('#pharmacist-operate-order-modal');
        var rejectOrderModal = $('#pharmacist-reject-order-modal');
        var cancelOrderModal = $('#pharmacist-cancel-order-modal');
        var patientSearch = $("#pharmacist-operate-order-block select[name='patient']");
        var addressSearch = $("#pharmacist-operate-order-block select[name='address']");
        var productSelectRequest;
        var patientSelectRequest;
        var patientAddressRequest;
        var patient, addOrder, editOrder, confirmOrder;

        // ADD / CONFIRM / EDIT ORDER MODAL
        operateOrderModal.on('show.bs.modal', function (event) {
            var button = $(event.relatedTarget);
            var availableDeliveryWeekdays = $('#availableDeliveryWeekdays').text().split(',').map(function(item) {
                return parseInt(item, 10);
            });
            var unavailableDeliveryWeekdays = convertWeekdays(availableDeliveryWeekdays);

            patient = button.data('user');
            addOrder = button.data('add');
            editOrder = button.data('edit');
            confirmOrder = button.data('confirm');
            // console.log('🏵🏵🏵availableDeliveryWeekdays', availableDeliveryWeekdays);
            // console.log('🏵🏵🏵user id:', patient);
            // console.log('🏵🏵🏵add new order', addOrder);
            // console.log('🏵🏵🏵edit order id:', editOrder);
            // console.log('🏵🏵🏵confirm', confirmOrder);

            // If the show event is returning from the datepicker,
            // we don't need to continue...
            if ($(event.target).hasClass('js-datepicker')) {
                return false;
            }

            var modal = $(this);
            var form = modal.find('form');

            form.attr('action', form.data('action'));
            var submitButton = modal.find('button[type=submit]');
            var patientSelectize = patientSearchSelectize[0].selectize;
            var addressSelectize = addressSearchSelectize[0].selectize;

            $('#productListTable tbody').html('');
            productsCount = 0;

            // Setup the modal title/buttons to refer to a new order
            modal.find('.block-title').text(modal.find('.block-title').data('add'));
            submitButton.removeClass('btn-danger').addClass('btn-primary').find('span').text(submitButton.find('span').data('add'));
            submitButton.find('i.fa').removeClass('fa-warning').addClass('fa-plus');

            // Clear the form
            form.trigger("reset");
            form.validate().resetForm();
            operateOrderModal.find('.has-error').removeClass('has-error');
            patientSelectize.enable();
            patientSelectize.clearOptions();
            addressSelectize.disable();
            addressSelectize.clearOptions();
            modal.data('isEditing', false);
            modal.data('isConfirming', false);
            modal.data('hasPatient', false);
            modal.find('.active-orders-info').removeClass('fadeIn fadeOut').hide();
            // Disable unavilable delivery weekday
            modal.find('input[name="eta"]').datepicker(
                'setDaysOfWeekDisabled', unavailableDeliveryWeekdays,
                'setStartDate', moment().format('YYYY-MM-DD')
            );
            modal.find('.js-selectize.selectized').each(function(){
                if (this.selectize) {
                    this.selectize.clear();
                }
            });

            // If we've defined a patient to save the order against, load the data
            // for the patient and prefill the field
            if (patient) {
                modal.data('hasPatient', true);
                modal.find('#pharmacist-operate-order-block').addClass('block-opt-refresh');
                productSelectRequest && productSelectRequest.abort();
                $.ajax({
                    url: patientSearch.data('source') + '/' + encodeURIComponent(patient),
                    type: 'GET',
                    error: function(error) {
                        console.warn('Error loading data', error);
                        modal.find('#pharmacist-operate-order-block').removeClass('block-opt-refresh');
                    },
                    success: function(res) {
                        var resPatient = _.find(res.result,{id:patient});
                        if (patientSelectRequest) {
                            patientSelectRequest.abort();
                        }
                        // if (productSelectRequest) {
                        //     productSelectRequest.abort();
                        // }
                        patientSelectize.clearOptions();
                        patientSelectize.addOption(resPatient);
                        patientSelectize.setValue(patient);

                        if (!editOrder && !confirmOrder) {
                            modal.find('#pharmacist-operate-order-block').removeClass('block-opt-refresh');
                        }
                    }
                });
            };

            // Call the Load Order function once the patient has been updated
            var loadOrder = function (patient) {
                console.log('patient is', patient);
                if (!patient.length) return;

                // Remove the listener to ensure we only fire once
                this.off('change', loadOrder);

                // Disable the patient select, as the user cannot be changed on an existing order
                patientSelectize.disable();

                // Load the Order data
                $.ajax({
                    url: button.data('source'),
                    type: 'GET',
                    error: function() {
                        modal.find('#pharmacist-operate-order-block').removeClass('block-opt-refresh');
                    },
                    success: function(data) {
                        var order = data.data; 
                        console.log('loaded order data is', order, '\n\nphone', order.deliveryAddress.data.phone);
                        var promises = [];

                        var loadAddressPromise = $.Deferred();
                        promises.push(loadAddressPromise);
                        // Set the Address
                        addressSelectize.load(function(callback) {
                            var self = this;
                            self.disable();
                            patientAddressRequest && patientAddressRequest.abort();
                            $.ajax({
                                url: $('.patient-lookup').data('address') + '/' + encodeURIComponent(patient),
                                type: 'GET',
                                data: {
                                    forOrder: order.id
                                },
                                error: function(error) {
                                    console.warn('Error getting patient address from patient list change', error);
                                    loadAddressPromise.resolve('false');
                                    callback();
                                },
                                success: function(result) {
                                    self.clearOptions();
                                    self.enable();
                                    callback(result);
                                    console.log('delivery addresses', result, '\n\norder delivery address', order.deliveryAddress.data);
                                    self.setValue(order.deliveryAddress.data.id);
                                    loadAddressPromise.resolve('true');
                                }
                            });
                        });

                        // Set the Phone
                        modal.find('input[name="phone"]').val(order.deliveryAddress.data.phone);

                        // Set the ETA
                        var eta = moment.utc(order.eta.date).local().format('YYYY-MM-DD');

                        // If ETA is in the past, we need to allow the datepicker to include it
                        if (moment(eta).isBefore(moment())) {
                            modal.find('input[name="eta"]').datepicker('setStartDate', eta).datepicker('update', eta);
                        } else{
                            modal.find('input[name="eta"]').datepicker('update', eta);
                        }

                        // Get the Courier
                        if (modal.find('select[name="courier"]').length) {
                            modal.find('select[name="courier"]')[0].selectize.setValue(order.courier.data.id);
                        }
                        
                        // Load the Products for the order as promises to track progress
                        
                        _.forEach(order.stock.data, function(product, key){
                            // console.log('\n\nproduct is', product, '\tkey is', key);
                            addProductRow(product.id);
                            var $productRow = $('#productListTable tbody tr').eq(key);
                            var productSelect = $productRow.find('select.js-selectize-product')[0].selectize;
                            // console.log('\n\nproductSelect', $productRow.find('select.js-selectize-product')[0]);

                            var loadProductPromise = $.Deferred();
                            // var loadLotPromise = $.Deferred();

                            var loadProduct = function(data){
                                this.setValue(product.skuWithPaidStatusString);
                                this.enable();
                                loadProductPromise.resolve('true');
                                this.off('load', loadProduct);
                            };

                            // var loadLot = function(data){
                            //     var option = _.find(this.options, {lot:product.vial});
                            //     if (option) {
                            //         this.setValue(option.stockId);
                            //     };
                            //     loadLotPromise.resolve('true');
                            //     this.off('load', loadLot);
                            // };

                            productSelect.on('load', loadProduct);

                            promises.push(loadProductPromise);
                            // promises.push(loadLotPromise);

                            // $productRow.find('input[name*=dosage]').val(product.vialDosage);
                            $productRow.find('input[name*=quantity]').val(product.quantity);
                            $productRow.find('input[name*=volume]').val(product.volume);
                            // $productRow.find('input[name*=paid]').val(product.paid);
                        })

                        // Once all product information is available, clear the loader
                        $.when.apply($,promises).done(function () {
                            modal.find('#pharmacist-operate-order-block').removeClass('block-opt-refresh');
                        });
                    }
                });
            }

            // If we add new order, load the selected patient's phone and address
            if (addOrder) {
                console.log('---- Add New Order Clicked ----')
                addProductRow();
                // Setup the modal window for the order.
                // modal.find('#pharmacist-operate-order-block').addClass('block-opt-refresh');
                form.attr('action', button.data('action'));
                modal.find('input[name="phone"]').val('');
                modal.find('input[name="eta"]').val('');
            } // End If Add New Order

            // If we confirm an order, load the data for the order and fill the form
            if (confirmOrder) {
                console.log('\n\n\n---- Confirm Order Clicked ----\n\n\n');

                modal.data('isConfirming', true);

                // Setup the modal window for the order.
                modal.find('#pharmacist-operate-order-block').addClass('block-opt-refresh');
                form.attr('action', button.data('action'));

                // Update the Title and buttons to refer to editing the order
                modal.find('.block-title').text(modal.find('.block-title').data('confirm'));
                submitButton.addClass('btn-success').removeClass('btn-primary').find('span').text(submitButton.find('span').data('confirm'));
                submitButton.find('i.fa').removeClass('fa-plus').addClass('fa-check');
                patientSelectize.on('change', loadOrder);
            } // End If Confirm Order

            // If we've defined an order to edit, load the data for the order and
            // fill the form
            if (editOrder) {
                console.log('\n\n\n---- Edit Order Clicked ----\n\n\n');
                modal.data('isEditing', true);
                // Setup the modal window for the order.
                modal.find('#pharmacist-operate-order-block').addClass('block-opt-refresh');
                form.attr('action', button.data('action'));

                // Update the Title and buttons to refer to editing the order
                modal.find('.block-title').text(modal.find('.block-title').data('edit'));
                submitButton.addClass('btn-danger').removeClass('btn-primary').find('span').text(submitButton.find('span').data('edit'));
                submitButton.find('i.fa').removeClass('fa-plus').addClass('fa-warning');
                patientSelectize.on('change', loadOrder);
            }  // End If Edit Order
        });

        $('#search-eta').datepicker({
            todayHighlight: true,
            language: $('html').attr('lang'),
        })
            .on('changeDate', function(e) {
              $('#activeOrders').DataTable().draw();
            })
            .on('clearDate', function(e) {
              $('#activeOrders').DataTable().draw();
            });

        $('#search-eta input[name="start"]').on('changeDate', function(e) {
            var $end = $(e.target).siblings('[name="end"]');
            if (moment($end.datepicker('getDate')).isSame(moment(e.date))) {
                $end.datepicker('show');
            }
        });

        addressSearchSelectize = addressSearch.selectize({
            valueField: 'id',
            labelField: 'label',
            searchField: 'label',
            options: [],
            create: false,
            preload: true,
            render: {
                option: function(item) {
                    var caption = item.caption ? item.caption : null;
                    var label = item.label;
                    return '<div>' +
                        (caption ? '<div class="text-muted">' + caption + ': </div>' : '') +
                        '<span>' + label + '</span>' +
                    '</div>';
                }
            }
        });

        // Initiate the Patient Search select box
        patientSearchSelectize = patientSearch.selectize({
            valueField: "id",
            labelField: "label",
            searchField: ["label", "id"],
            loadThrottle: 500,
            selectOnTab: true,
            create: false,
            persist: false,
            highlight: false,
            load: function(query, callback) {
                var self = this;
                if (!query.length){
                    this.clearOptions();
                    return false;
                } 
                if (patientSelectRequest) {
                    console.warn('Patient search has request, aborting', patientSelectRequest);
                    patientSelectRequest.abort()
                }
                patientSelectRequest = $.ajax({
                    url: this.$input.data('source') + '/' + encodeURIComponent(query),
                    type: 'GET',
                    error: function() {
                        console.warn('Selectize load patient error');
                        callback();
                    },
                    success: function(res) {
                        // console.info('Got Patient Search response', res, res.result.slice(0, 10));
                        self.clearOptions();
                        self.enable();
                        callback(res.result.slice(0, 10));
                    }
                });
            },
            onChange: function(value) {
                // Update the products field to display products
                // based on the selected user's default product
                console.log('selected patient id is', value);

                if (!value.length){
                    $('.active-orders-info').removeClass('fadeIn').addClass('fadeOut');
                    return;
                };

                if (addOrder || editOrder) {
                    if (patientAddressRequest) {
                        console.warn('Patient address search has request, aborting', patientAddressRequest);
                        patientAddressRequest.abort();
                    }

                    // Load patient info: phone number & address
                    var addressSelectize = addressSearchSelectize[0].selectize;
                    var addressArray = [];
                    addressSelectize.load(function(callback) {
                        var self = this;
                        self.disable();
                        patientAddressRequest && patientAddressRequest.abort();
                        $.ajax({
                            url: $('.patient-lookup').data('address') + '/' + encodeURIComponent(value),
                            type: 'GET',
                            error: function(error) {
                                console.warn('Error getting patient address from patient list change', error);
                                callback();
                            },
                            success: function(result) {
                                console.log('address result is', result);
                                addressArray = result;
                                // var primaryAddress = _.find(result, function(o) { return o.primary === true; });
                                self.clearOptions();
                                self.enable();
                                callback(result);
                                // primaryAddress ? addressSelectize.setValue(primaryAddress.id) : addressSelectize.setValue(null);
                                addressSelectize.on('change', function(v) {
                                    if (addOrder) {
                                        var selectedAddress = _.find(addressArray, function(o) { return o.id == v; });
                                        console.log('\n\n\n\n\nSelected address is', selectedAddress);
                                        var phoneNumber = _.get(selectedAddress, 'addressPhone', null);
                                        operateOrderModal.find('input[name="phone"]').val(phoneNumber);
                                    }
                                });
                            }
                        });
                    });
                }

                if (this.options[value].activeOrders > 0 && !operateOrderModal.data('isEditing') && !operateOrderModal.data('isConfirming')) {
                    $('.active-orders-info').show().removeClass('fadeOut').addClass('fadeIn').find('.active-orders-label').text(this.options[value].activeOrdersLabel);
                } else {
                    $('.active-orders-info').removeClass('fadeIn').addClass('fadeOut');
                }

                var productSelect = $('.js-selectize-product');

                if (productSelect.length) {
                    var productSelectize = productSelect[0].selectize;
                    productSelectize.disable();
                    productSelectize.clearOptions();
                    productSelectize.load(function(callback) {
                        if (productSelectRequest) {
                            console.warn('Product search has request, aborting', productSelectRequest);
                            productSelectRequest.abort();
                        }

                        // productSelectRequest && productSelectRequest.abort();
                        productSelectRequest = $.ajax({
                            url: $('#productListTableProduct').data('source') + '/' + encodeURIComponent(patientSearch.val()),
                            error: function(error) {
                                console.warn('Error getting products from patient list change', error);
                                callback();
                            },
                            success: function(res) {
                                console.log('productSelectize', res);
                                productSelectize.enable();
                                callback(res);
                            }
                        });
                    });
                } else {
                    console.log('Couldn\'t find product select');
                }
            }
        });

        var xhr;
        var productsCount = 0;

        var addProductRow = function (loadExisting) {
            loadExisting = typeof loadExisting !== 'undefined' ? loadExisting : false;
            console.log('☄️☄️☄️loadExisting stock:', loadExisting);
            productsCount++;
            var $table = $('#productListTable tbody');
            var row, cell, productSelect, lotSelect, vialVolume, quantity, volume, removeRow;

            row = $('<tr>');

            // Create the Product Select Field
            // var productList = $('#product-list').data('products').split(',');
            cell = $('<td class="form-group">').appendTo(row);
            cell = $('<div class="form-material form-material-primary">').appendTo(cell);
            productSelect = $('<select>', {
                name: 'products[' + productsCount + '][product]',
                class: 'form-control js-selectize-product',
                required: true
            }).appendTo(cell);

            // Create the Stock ID Field
            cell =  $('<td class="form-group hidden">').appendTo(row);
            cell = $('<div class="form-material form-material-primary">').appendTo(cell);
            lotSelect = $('<select>', {
                name: 'products[' + productsCount + '][stockId]',
                class: 'form-control js-selectize-lot',
                required: false
            }).appendTo(cell);

            // Create the Vial Volume Numerical Field
            cell =  $('<td class="form-group hidden">').appendTo(row);
            cell = $('<div class="form-material form-material-primary">').appendTo(cell);
            vialVolume = $('<input>', {
                type: 'number',
                name: 'products[' + productsCount + '][dosage]',
                class: 'form-control',
                required: false,
                step: 5,
                min: 0
            }).appendTo(cell);

            // Create the Quantity Numerical Input
            cell =  $('<td class="form-group">').appendTo(row);
            cell = $('<div class="form-material form-material-primary">').appendTo(cell);
            quantity = $('<input>', {
                type: 'number',
                name: 'products[' + productsCount + '][quantity]',
                class: 'form-control',
                required: true,
                step: 1,
                min: 1
            }).appendTo(cell);

            // Create the Volume Numerical Input
            // cell =  $('<td class="form-group">').appendTo(row);
            // cell = $('<div class="form-material form-material-primary">').appendTo(cell);
            // volume = $('<input>', {
            //     type: 'text',
            //     name: 'products[' + productsCount + '][volume]',
            //     class: 'form-control',
            //     required: true,
            // }).appendTo(cell);

            // Create the Paid Status Input
            // cell =  $('<td class="form-group">').appendTo(row);
            // cell = $('<div class="form-material form-material-primary">').appendTo(cell);
            // paid = $('<input>', {
            //     type: 'text',
            //     name: 'products[' + productsCount + '][paid]',
            //     class: 'form-control',
            //     required: true,
            // }).appendTo(cell);

            // Create the Delete Row item
            cell =  $('<td class="form-group">').appendTo(row);
            cell = $('<div class="form-material form-material-primary">').appendTo(cell);
            removeRow = $('<i class="fa fa-trash-o" style="cursor:pointer"></i>').appendTo(cell);

            removeRow.on('click', function () {
                row.remove();
                if (!$('#productListTable tbody>tr').length) {
                    productsCount = 0;
                    addProductRow();
                }
            });

            // Initialise the Product Selection box
            // Values are loaded from the zb.products object
            // var fieldValue = addOrder || addProductButtonClicked ? 'label' : 'name';

            productSelect.selectize({
                valueField: 'label',
                labelField: 'label',
                searchField: 'label',
                options: [],
                placeholder: $('#productListTableProduct').data('placeholder'),
                create: false,
                preload: true,
                load: function(query, callback) {
                    var self = this;
                    self.disable();
                    productSelectRequest && productSelectRequest.abort();
                    console.log('🔴load product from order');
                    if (!addOrder) {
                        $.ajax({
                            url: $('#productListTableProduct').data('source') + '/' + encodeURIComponent(patientSearch.val()),
                            data: {
                                forOrderStock: loadExisting 
                            },
                            error: function(error) {
                                console.warn('Error loading initial product list', error);
                                // callback();
                            },
                            success: function(res) {
                                console.log('🔴success to get the product', res);
                                self.enable();
                                callback(res);
                            }
                        });
                    }
                },
                onChange: function(value) {
                    // Update the Lot Number field to display valid lot numbers for the
                    // selected product.
                    $(this.$input).valid();
                    if (!value.length) return;

                    var productSelectize = productSelect[0].selectize;
                    console.log('🔴selected product\n\n', productSelectize.getValue());
                    console.log('🔴product options', productSelectize.options[productSelectize.getValue()]);
                    productSelect.data('vialVolume', productSelectize.options[productSelectize.getValue()].volume);

                    if (!value.length) return;
                    var $lotSelectize = lotSelect;
                    var lotSelectize = $lotSelectize[0].selectize;
                    lotSelectize.disable();
                    lotSelectize.clearOptions();
                    // console.log('lot search url', $('#productListTableLot').data('source') + '/' + encodeURIComponent(value) + '/' + encodeURIComponent(patientSearch.val()));
                    lotSelectize.load(function(callback) {
                        // xhr && xhr.abort();
                        xhr = $.ajax({
                            url: $('#productListTableLot').data('source') + '/' + encodeURIComponent(value) + '/' + encodeURIComponent(patientSearch.val()),
                            data: {
                                forOrderStock: loadExisting
                            },
                            success: function(results) {
                                console.log('🔴Got the product!', results);
                                if (results && results.length === 1) {
                                    // console.log('Setting lot selectize value', results[0]);
                                    vialVolume.val(results[0].dosage);
                                    lotSelectize.addOption(results[0]);
                                    lotSelectize.setValue(results[0].stockId);
                                }

                                lotSelectize.enable();
                                callback(results);
                            },
                            error: function(e) {
                                console.warn('Error getting lot numbers!', e);
                                callback();
                            }
                        })
                    });
                }
            });

            // Initialise the Lot Selection
            // Values are loaded from the onChange event of the 
            lotSelect.selectize({
                'valueField': 'stockId',
                'labelField': 'lot',
                'searchField': ['name'],
                'create': false,
                placeholder: $('#productListTableLot').data('placeholder'),
                onChange: function (value) {
                    // console.log('lot value', value);
                    $(this.$input).valid();
                    if (value && this.options[value]) {
                        quantity.attr('max', this.options[value].available);
                        quantity.attr('data-original-title', this.options[value].availableTitle);
                        // volume.attr('max', this.options[value].volumeAvailable);
                        // volume.attr('data-original-title', this.options[value].volumeAvailableTitle);
                        // console.log('Set Lot Number', value, 'with', this.options[value].availableTitle);
                    } else {
                        quantity.removeAttr('max');
                        quantity.removeAttr('data-original-title');
                        // volume.removeAttr('max');
                        // volume.removeAttr('data-original-title');
                    }
                }
            });

            // Disable the Lot Selection on init, as we require the product to be selected first.
            lotSelect[0].selectize.disable();

            // quantity.on('change', function(value){
            //     console.log('quantity change', vialVolume.val());
            //     if (vialVolume.val()) {
            //         volume.val(vialVolume.val() * $(this).val()).valid();
            //     }
            // });

            // volume.on('change', function(value){
            //     console.log('volume change', vialVolume.val());
            //     if (vialVolume.val()) {
            //         quantity.val($(this).val() / vialVolume.val()).valid();
            //     }
            // });

            quantity.tooltip({
                animation: false,
                trigger: 'hover focus',
                placement: 'bottom'
            });

            // volume.tooltip({
            //     animation: false,
            //     trigger: 'hover focus',
            //     placement: 'bottom'
            // });

            row.appendTo($table);
        };
        
        $("#pharmacist-operate-order-modal .add-product-button").on('click', function () {
            console.log('Add Product button clicked');
            addProductRow();
        });

        // REJECT PATIENT ORDER MODAL
        rejectOrderModal.on('show.bs.modal', function (event) {
            var modal = $(this);
            var button = $(event.relatedTarget) // Button that triggered the modal
            var row = button.parents('tr');
            var table = row.parents('.table');

            var id = row.data('id');
            var lot = button.data('lot');
            var product = button.data('product');
            var action = button.data('action');

            // Text replacement
            modal.find('[data-replace = "product"]').text(product);
            modal.find('[data-replace = "lot"]').text(lot);
            modal.find('p:contains(":product")').html(function () {
                return $(this).html().replace(':product', '<strong data-replace="product">' + product + '</strong>'); 
            });
            modal.find('p:contains(":lot")').html(function () {
                return $(this).html().replace(':lot', '<strong data-replace="lot">' + lot + '</strong>'); 
            });

            // Unbind submit action on removal
            modal.on('hide.bs.modal', function (e) {
              modal.find('#submitRejectOrder').unbind();
            });

            modal.find('#submitRejectOrder').click(function (e) {
                return $.ajax({
                    url: action,
                    dataType:'json',
                    type:'post',
                    success: function(result) {
                        
                        if (button.parents('.table.dataTable').length) {
                            // If the action comes from the datatable, just remove the row with the datatable API 
                            $('#availableInventory.dataTable').DataTable().row('[data-id="' + id + '"]').remove().draw( false );
                        }
                        modal.modal('hide');

                        if ($('#availableInventory.dataTable').DataTable().rows().count() === 0) {
                            location.reload();
                        }
                    },
                    error: function (error) {
                        // alert('error');
                        console.warn(error);
                    }
                });
            });
        });

        // CANCEL PATIENT ORDER MODAL
        cancelOrderModal.on('show.bs.modal', function (event) {
            console.log('cancel order modal opens');
            var modal = $(this);
            var button = $(event.relatedTarget) // Button that triggered the modal
            var row = button.parents('tr');
            var table = row.parents('.table');

            var id = row.data('id');
            var lot = button.data('lot');
            var product = button.data('product');
            var action = button.data('action');

            // Text replacement
            modal.find('[data-replace = "product"]').text(product);
            modal.find('[data-replace = "lot"]').text(lot);
            modal.find('p:contains(":product")').html(function () {
                return $(this).html().replace(':product', '<strong data-replace="product">' + product + '</strong>'); 
            });
            modal.find('p:contains(":lot")').html(function () {
                return $(this).html().replace(':lot', '<strong data-replace="lot">' + lot + '</strong>'); 
            });

            // Unbind submit action on removal
            modal.on('hide.bs.modal', function (e) {
              modal.find('#submitCancelOrder').unbind();
            });

            modal.find('#submitCancelOrder').click(function (e) {
                return $.ajax({
                    url: action,
                    dataType:'json',
                    type:'post',
                    success: function(result) {
                        
                        if (button.parents('.table.dataTable').length) {
                            // If the action comes from the datatable, just remove the row with the datatable API 
                            $('#availableInventory.dataTable').DataTable().row('[data-id="' + id + '"]').remove().draw( false );
                            location.reload();
                        }
                        modal.modal('hide');

                        if ($('#availableInventory.dataTable').DataTable().rows().count() === 0) {
                            location.reload();
                        }
                    },
                    error: function (error) {
                        // alert('error');
                        console.warn(error);
                    }
                });
            });
        });

        var deleteOrderModal = $('#pharmacist-delete-order-modal');
        deleteOrder(deleteOrderModal, event);
    };

    /*
     * Order Stock Managment Helper
     * Provide support for:
     * - Update Inventory Stock Level Modal
     * - Mark as Consumed Inventory Stock Level Modal
     */

    var uiHelperOrderStockManagement = function(){
        // INVENTORY/USER LIST UPDATE STOCK LEVELS MODAL
        // Load data from the selected row. Add support for re-calculating stock levels

        jQuery('#update-inventory-modal').on('show.bs.modal', function (event) {
            // Close any existing modals
            var currentModal = $('.modal.in');
            currentModal.modal('hide');

            // Initiate the modal, get data for the selected item
            var modal = $(this);
            var button = $(event.relatedTarget) // Button that triggered the modal
            var row = button.parents('tr');
            var table = row.parents('.table');
            var previewTable = modal.find('#stockPreviewTable');
            var action = button.data('action');

            modal.find('form').attr('action', action).validate().resetForm();

            // Extract Product data from the row
            var id = row.data('id');
            var lot = row.find('td[data-content="lot"]').text();
            var product = row.find('td[data-content="name"]').text();
            var expiry = row.find('td[data-content="expiry"]').text();
            var patient = table.data('name');
            var vialVolume = row.find('td[data-content="name"]').data('volume');
            var uom = row.find('td[data-content="name"]').data('uom');

            // Update preview table with product data info
            modal.find('[data-replace = "product"]').text(product);
            modal.find('[data-replace = "lot"]').text(lot);
            modal.find('[data-replace = "expiry"]').text(expiry);
            modal.find('[data-replace = "patient"]').text(patient);

            // Extract stock level data from the row and populate the preview table
            var totalVolumeCell = row.find('td[data-content="totalQuantity"]');
            totalVolumeCell = previewTable.find('[data-replace = "totalVolume"]').html(totalVolumeCell.html());
            
            var consumedQuantityCell = row.find('td[data-content="consumedQuantity"]');
            consumedQuantityCell = previewTable.find('[data-replace = "ordered"]').html(consumedQuantityCell.html());

            var inStockCell = row.find('td[data-content="availableQuantity"]');
            inStockCell = previewTable.find('[data-replace = "inStock"]').html(inStockCell.html());
            inStockCell.removeClass('bg-danger text-white');

            // Get individual content elements in the preview row for later updating
            var totalBoxesContent = previewTable.find('[data-content="totalBoxes"]');
            var totalVolumeContent = previewTable.find('[data-content="totalVolume"]');
            var consumedBoxesContent = previewTable.find('[data-content="consumedBoxes"]');
            var consumedVolumeContent = previewTable.find('[data-content="consumedVolume"]');
            var availableBoxesContent = previewTable.find('[data-content="availableBoxes"]');
            var availableVolumeContent = previewTable.find('[data-content="availableVolume"]');

            // Extract Total Volume data points from the row data
            var quantity = row.find('td[data-content="totalQuantity"] span[data-content="totalBoxes"]').data('value');
            var volume = quantity * vialVolume;

            var orderedQuantity = row.find('td[data-content="consumedQuantity"] span[data-content="consumedBoxes"]').data('value');
            var orderedVolume = orderedQuantity * vialVolume;

            // Initiate and populate the input fields
            var quantityInput = modal.find('[name="totalInventory[quantity]"]');
            var volumeInput = modal.find('[name="totalInventory[volume]"]');
            var orderedQuantityInput = modal.find('[name="orderedInventory[quantity]"]');
            var orderedVolumeInput = modal.find('[name="orderedInventory[volume]"]');
            
            quantityInput.val(quantity);
            volumeInput.val(volume);
            orderedQuantityInput.val(orderedQuantity);
            orderedVolumeInput.val(orderedVolume);
            volumeInput.attr('step', vialVolume);
            orderedVolumeInput.attr('step', vialVolume);

            // Handle toggling between "Total Volume" and "Ordered Volume" in the modal window
            var setSectionOrdered = function () {
                consumedQuantityCell.addClass('bg-success text-white');
                totalVolumeCell.removeClass('bg-success text-white');
                modal.find('.form-group.ordered-inventory').show();
                modal.find('.form-group.total-inventory').hide();
            }

            var setSectionTotal = function () {
                totalVolumeCell.addClass('bg-success text-white');
                consumedQuantityCell.removeClass('bg-success text-white');
                modal.find('.form-group.total-inventory').show();
                modal.find('.form-group.ordered-inventory').hide();
            }

            // Load default view 
            if (button.data('section') === "orderedVolume") {
                setSectionOrdered();
            } else {
                setSectionTotal();
            }

            // Load view on click of Preview Table
            previewTable.on('click', '[data-replace="totalVolume"]', function (e) {
                setSectionTotal();
            });
            previewTable.on('click', '[data-replace="ordered"]', function (e) {
                setSectionOrdered();
            });

            // Update the row preview and validation rules when an input value changes
            var updateRowPreview = function () {
                // Determine available quantities
                availableQuantity = quantity - orderedQuantity;
                availableVolume = availableQuantity * vialVolume;

                // Update the preview table
                totalBoxesContent.text(quantity.toLocaleString() + ' ' + totalBoxesContent.data('unit'));
                totalVolumeContent.text(volume.toLocaleString() + ' ' + uom);
                consumedBoxesContent.text(orderedQuantity.toLocaleString() + ' ' + consumedBoxesContent.data('unit'));
                consumedVolumeContent.text(orderedVolume.toLocaleString() + ' ' + uom);
                availableBoxesContent.text(availableQuantity.toLocaleString() + ' ' + availableBoxesContent.data('unit'));
                availableVolumeContent.text(availableVolume.toLocaleString() + ' ' + uom);

                // Highlight the preview table on error (eg. available qty of less than 0)
                if (availableQuantity < 0) {
                    inStockCell.addClass('bg-danger text-white');
                } else {
                    inStockCell.removeClass('bg-danger text-white');
                }

                // Update input validation min/max values
                quantityInput.attr('min', orderedQuantity);
                orderedQuantityInput.attr('max', quantity);

                // Re-validate form with new values
                modal.find('form').valid();
            };

            // Detect change events on inputs
            quantityInput.on('change', function(value){
                quantity = parseInt(quantityInput.val());
                volume = quantity * vialVolume;
                volumeInput.val(volume);
                updateRowPreview();
            });

            volumeInput.on('change', function(value){
                volume = parseInt(volumeInput.val());
                quantity = volume / vialVolume;
                quantityInput.val(quantity);
                updateRowPreview();
            });

            orderedQuantityInput.on('change', function(value){
                orderedQuantity = parseInt(orderedQuantityInput.val());
                orderedVolume = orderedQuantity * vialVolume;
                orderedVolumeInput.val(orderedVolume);
                updateRowPreview();
            });

            orderedVolumeInput.on('change', function(value){
                orderedVolume = parseInt(orderedVolumeInput.val());
                orderedQuantity = orderedVolume / vialVolume;
                orderedQuantityInput.val(orderedQuantity);
                updateRowPreview();
            });

            // Unbind actions on removal
            modal.on('hide.bs.modal', function (e) {
              quantityInput.unbind();
              volumeInput.unbind();
              orderedQuantityInput.unbind();
              orderedVolumeInput.unbind();
              previewTable.unbind();

              // If we arrived here from a modal, show it again.
              currentModal.modal('show');
            });
        });


        // MARK AS CONSUMED MODAL
        jQuery('#confirm-consume-stock').on('show.bs.modal', function (event) {
            // Close any existing modals
            var currentModal = $('.modal.in');
            currentModal.modal('hide');

            var modal = $(this);
            var button = $(event.relatedTarget) // Button that triggered the modal
            var row = button.parents('tr');
            var table = row.parents('.table');

            var id = row.data('id');
            var lot = button.data('lot');
            var product = button.data('product');
            var action = button.data('action');
            var quantity = button.data('quantity');

            modal.find('form').attr('action', action).validate().resetForm();

            // Text replacement
            modal.find('[data-replace = "product"]').text(product);
            modal.find('[data-replace = "lot"]').text(lot);
            modal.find('[data-replace = "quantity"]').text(quantity);
            modal.find('p:contains(":product")').html(function () {
                return $(this).html().replace(':product', '<strong data-replace="product">' + product + '</strong>'); 
            });
            modal.find('p:contains(":lot")').html(function () {
                return $(this).html().replace(':lot', '<strong data-replace="lot">' + lot + '</strong>'); 
            });

            modal.find('p:contains(":quantity")').html(function () {
                return $(this).html().replace(':quantity', '<strong data-replace="quantity">' + quantity + '</strong>'); 
            });

            // Unbind actions on removal
            modal.on('hide.bs.modal', function (e) {
              // If we arrived here from a modal, show it again.
              currentModal.modal('show');
            });

        });
    };

    function deleteOrder(deleteOrderModal, event) {
        // DELETE PATIENT ORDER MODAL
        deleteOrderModal.on('show.bs.modal', function (event) {
            console.log(this.id, 'opens');
            var modal = $(this);
            var button = $(event.relatedTarget) // Button that triggered the modal
            var row = button.parents('tr');
            var table = row.parents('.table');

            var id = row.data('id');
            var lot = button.data('lot');
            var product = button.data('product');
            var action = button.data('action');

            // Text replacement
            modal.find('[data-replace = "product"]').text(product);
            modal.find('[data-replace = "lot"]').text(lot);
            modal.find('p:contains(":product")').html(function () {
                return $(this).html().replace(':product', '<strong data-replace="product">' + product + '</strong>'); 
            });
            modal.find('p:contains(":lot")').html(function () {
                return $(this).html().replace(':lot', '<strong data-replace="lot">' + lot + '</strong>'); 
            });

            // Unbind submit action on removal
            modal.on('hide.bs.modal', function (e) {
              modal.find('#submitRemoval').unbind();
            });

            modal.find('#submitRemoval').click(function (e) {
                return $.ajax({
                    url: action,
                    dataType:'json',
                    type:'post',
                    success: function(result) {
                        
                        if (button.parents('.table.dataTable').length) {
                            // If the action comes from the datatable, just remove the row with the datatable API 
                            $('#availableInventory.dataTable').DataTable().row('[data-id="' + id + '"]').remove().draw( false );
                            location.reload();
                        }
                        modal.modal('hide');

                        if ($('#availableInventory.dataTable').DataTable().rows().count() === 0) {
                            location.reload();
                        }
                    },
                    error: function (error) {
                        // alert('error');
                        console.warn(error);
                    }
                });
            });
        });
    }


    function orderDetails(event) {
        // console.log('details button clicked');
        var completedOrderDetails = $(event.currentTarget).data('source');
        // console.log('completedOrderDetails', completedOrderDetails);
        var completedOrdersModal = $('#completed-order-details-modal');
        var blockContent = completedOrdersModal.find('#completedOrderDetailsBlock');
        blockContent.addClass('block-opt-refresh');

        blockContent.load(completedOrderDetails, function () {
            completedOrdersModal.modal('show');
            if ($('#signature-block').length) {
                console.log('signature: Found');
                blockContent.find('#order-signature').load(function() {
                    blockContent.removeClass('block-opt-refresh');
                });
            } else {
                console.log('signature: Not found');
                blockContent.removeClass('block-opt-refresh');
            }
        });            
    }

    var uiHelperOrderHistory = function(){
        console.log('load orderHistory');
        var deleteOrderModal = $('#pharmacist-delete-order-modal');
        deleteOrder(deleteOrderModal, event);
        // On click of the Details button for a patient, dynamically
        // load the completed order details table from the defined URL, then
        // open the modal window.
        // ---- Completed Order Details Modal ---- //
        $('#activeOrders').DataTable().on('responsive-display', function() {
            $('.completed-order-details-btn').off('click');
            $('.completed-order-details-btn').click(function(event) {
                console.log('\n\n\n---- Order Details Clicked ----\n\n\n');
                orderDetails(event);
            });
        });
    };

    var uiPharmacistPatientManagement = function() {
        console.log('load pharmacistPatientManagement');
        $('#update-patient-enrollment-status').on('show.bs.modal', function (event) {
            console.log('update patient enrollment status modal is open');

            var modal = $(this);
            var button = $(event.relatedTarget) // Button that triggered the modal
            var row = button.parents('tr');
            var table = row.parents('.table');

            var id = row.data('id');
            var action = button.data('action');

            // Unbind submit action on removal
            modal.on('hide.bs.modal', function (e) {
              modal.find('#submit-update-enrollment-status').unbind();
            });

            modal.find('#submit-update-enrollment-status').click(function (e) {
                return $.ajax({
                    url: action,
                    type:'post',
                    success: function(result) {
                        modal.modal('hide');
                        location.reload();
                    },
                    error: function (error) {
                        console.warn(error);
                    }
                });
            });
        });
    };

    var uiHelperOrderManageDeliveries = function(){
        console.log('load orderManageDeliveries');
        var pickupOrderModal = $('#delivery-pickup-order-modal');
        var rescheduleOrderModal = $('#delivery-reschedule-order-modal');
        var confirmOrderDeliveredModal = $('#confirm-order-delivered');

        // ---- PickUp Order Modal ---- //
        pickupOrderModal.on('show.bs.modal', function (event) {
            var button = $(event.relatedTarget);
            var modal = $(this);
            var source = button.data('source');
            var action = button.data('action');
            var order = button.data('order');
            // console.log('selected order is', order, 'action', action, 'source', source);

            var productDetail = $('#pickup-modal-product-detail');
            productDetail.html('');
            var productText = '';

            $.ajax({
                url: source,
                type: 'GET',
                error: function(err) {
                    console.warn('Failed to find the order', err);
                },
                success: function(order) {
                    // console.log('selected order is', order.data);
                    _.each(order.data.stock.data, function(stock) {
                        productText += stock.quantity + ' x ' + '<strong class="text-uppercase">' + stock.productSKU + '</strong>' + '<br />';
                    });
                    productDetail.html(productText);
                }
            });

            // Unbind submit action on removal
            modal.on('hide.bs.modal', function (e) {
              modal.find('#submitPickupOrder').unbind();
            });

            modal.find('#submitPickupOrder').click(function (e) {
                return $.ajax({
                    url: action,
                    type:'post',
                    success: function(result) {
                        modal.modal('hide');
                        location.reload();
                    },
                    error: function (error) {
                        console.warn('Has error to confirm order picked up', error);
                    }
                });
            });
        });

        // ---- Reschedule Order Modal ---- //
        rescheduleOrderModal.on('show.bs.modal', function (event) {
            var modal = $(this);

            // Unbind submit action on removal
            modal.on('hide.bs.modal', function (e) {
              modal.find('#submitConfirmDelivery').unbind();
            });

            var eta = $('#deliveryOrderDataEta').html().trim().split(' ')[0];
            eta = moment.utc(eta).format('YYYY-MM-DD');
            console.log('eta', eta);

            // If ETA is in the past, we need to allow the datepicker to include it
            if (moment(eta).isBefore(moment())) {
                modal.find('input[name="eta"]').datepicker('setStartDate', eta).datepicker('update', eta);
            } else{
                modal.find('input[name="eta"]').datepicker('update', eta);
            }

            modal.find('#submitConfirmDelivery').click(function (e) {
                modal.find('#orderDeliveredBlock').addClass('block-opt-refresh');
            });
        });

        rescheduleOrderModal.on('hide.bs.modal', function (event) {
            var modal = $(this);
            modal.find('#orderDeliveredBlock').removeClass('block-opt-refresh');
        });

        // On click of the Details button for a patient, dynamically
        // load the completed order details table from the defined URL, then
        // open the modal window.
        // ---- Completed Order Details Modal ---- //
        $('.completed-order-details-btn').click(function(event) {
            console.log('order details button clicked from delivery dashboard');
            orderDetails(event);
        });

        $('#completedOrders-search-eta, #activeOrders-search-eta').datepicker({
            todayHighlight: true,
            language: $('html').attr('lang'),
        })
        .on('changeDate', function(e) {
            $('#activeOrders').DataTable().draw();
        })
        .on('clearDate', function(e) {
            $('#activeOrders').DataTable().draw();
        });

        // ---- Confirm Order Delivery Modal ---- //
        confirmOrderDeliveredModal.on('show.bs.modal', function (event) {
            var modal = $(this);

            // Unbind submit action on removal
            modal.on('hide.bs.modal', function (e) {
              modal.find('#submitConfirmDelivery').unbind();
            });

            var eta = $('#deliveryOrderDataEta').html().trim();
            etaDate = moment.utc(eta).format('YYYY-MM-DD');
            etaTime = moment.utc(eta).format('HH:mm');
            // console.log('converted eta', eta);
            modal.find('input[name="etaTime"]').datetimepicker({
                format: 'LT'
            });
            modal.find('input[name="etaDate"]').val(etaDate);
            modal.find('input[name="etaTime"]').val(etaTime);

            var courier = $('#deliveryOrderDataCourier').html().trim().split(' ')[0];
            modal.find('select[name="courier"]')[0].selectize.setValue(courier);

            var signaturePadWrapper = $('#signature-pad');
            var canvas = signaturePadWrapper.find('canvas')[0];
            // Detecting if running mobile browser
            // https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser
            if (typeof window.orientation !== 'undefined') {
                canvas.width = window.innerWidth * 0.9;
                canvas.height = 200;                
            }
            // console.log('canvas width', canvas.width, 'window.innerWidth', window.innerWidth);
            var signaturePad = new SignaturePad(canvas, {
                // It's Necessary to use an opaque color when saving image as JPEG;
                // this option can be omitted if only saving as PNG or SVG
                backgroundColor: 'rgb(251, 251, 251)'
            });

            var clearSignatureButton = document.getElementById('clear-signature');
            clearSignatureButton.addEventListener('click', function() {
                console.log('clear signature button clicked');
                signaturePad.clear();
            });

            // One could simply use Canvas#toBlob method instead, but it's just to show
            // that it can be done using result of SignaturePad#toDataURL.
            function dataURLToBlob(dataURL) {
                // Code taken from https://github.com/ebidel/filer.js
                var parts = dataURL.split(';base64,');
                var contentType = parts[0].split(':')[1];
                var raw = window.atob(parts[1]);
                var rawLength = raw.length;
                var uInt8Array = new Uint8Array(rawLength);

                for (var i = 0; i < rawLength; ++i) {
                    uInt8Array[i] = raw.charCodeAt(i);
                }

                return new Blob([uInt8Array], { type: contentType });
            }

            modal.find('#submitConfirmDelivery').click(function (e) {

                if( signaturePad.isEmpty()){
                    console.warn('Signature is empty');
                    return false;            
                }

                modal.find('#orderDeliveredBlock').addClass('block-opt-refresh');

                var dataUrl = signaturePad.toDataURL();
                var blob = dataURLToBlob(dataUrl);
                var url = window.URL.createObjectURL(blob);

                var result = JSON.stringify({
                    dataUrl: dataUrl,
                    url: url
                });

                // pass signature to hidden input
                modal.find('input[name="signature"]').val(result);
                // console.log('signature result', result);

                // This library does not listen for canvas changes, so after the canvas is automatically
                // cleared by the browser, SignaturePad#isEmpty might still return false, even though the
                // canvas looks empty, because the internal data of this library wasn't cleared. To make sure
                // that the state of this library is consistent with visual state of the canvas, you
                // have to clear it manually.
                signaturePad.clear();
            });
        });

        confirmOrderDeliveredModal.on('hide.bs.modal', function (event) {
            var modal = $(this);
            modal.find('#orderDeliveredBlock').removeClass('block-opt-refresh');
        });
    };

    var uiHelperCoordinatorDashboard = function(){
        $('.js-dataTable-enrolledCenters').parents('.custom-filters').find('.js-selectize.selectized.table-filter').each(function(){
            this.selectize.on('change', function(value){
                console.log('Table Filter Changed', value);
                if (value) {
                    this.$control.addClass('is-set');
                } else {
                   this.clear();
                   this.$control.removeClass('is-set');
                }
                $('.js-dataTable-enrolledCenters').DataTable().ajax.reload();
            })
        });

        $('#date-filter').datepicker({
            todayHighlight: true,
            language: $('html').attr('lang'),
        }).on('changeDate', function(e) {
            $(e.target).datepicker('hide')
            $('.js-dataTable-enrolledCenters').DataTable().ajax.reload();
        }).on('clearDate', function(e) {
            $('.js-dataTable-enrolledCenters').DataTable().ajax.reload();
        });

        $('#date-filter input[name="start"]').on('changeDate', function(e) {
            var $end = $(e.target).siblings('[name="end"]');
            if (moment($end.datepicker('getDate')).isSame(moment(e.date))) {
                $end.datepicker('show');
            }
        })
    };

    var uiHelperDistributorManageOrder = function() {
        console.log('load distributorManageOrder');
        var operateOrderModal = $('#distributor-operate-order-modal');
        var addressSearch = $("#distributor-operate-order-modal select[name='address']");

        addressSearchSelectize = addressSearch.selectize({
            valueField: 'id',
            labelField: 'label',
            searchField: 'label',
            options: [],
            create: false,
            preload: true,
            render: {
                option: function(item) {
                    var caption = item.caption ? item.caption : null;
                    var label = item.label;
                    return '<div>' +
                        (caption ? '<div class="text-muted">' + caption + ': </div>' : '') +
                        '<span>' + label + '</span>' +
                    '</div>';
                }
            }
        });
        var addressSelectize = addressSearchSelectize[0].selectize;

        operateOrderModal.on('show.bs.modal', function (event) {
            // If the show event is returning from the datepicker,
            // we don't need to continue...
            if ($(event.target).hasClass('js-datepicker')) {
                return false;
            }
            var modal = $(this);
            var form = modal.find('form');
            var button = $(event.relatedTarget);
            var action = button.data('action');
            var operate = button.data('operate');
            var submitButton = modal.find('button[type=submit]');
            // console.log('**** operate:', operate, '\n**** action', action);
            modal.find('select[name="courier"]')[0].selectize.setValue('');
            modal.find('#distributorOperateOrderBlock').addClass('block-opt-refresh');

            // Clear the form
            form.trigger("reset");
            form.validate().resetForm();
            modal.find('.has-error').removeClass('has-error');
            addressSelectize.enable();
            addressSelectize.clearOptions();

            var availableDeliveryWeekdays = $('#availableDeliveryWeekdays').text().split(',').map(function(item) {
                return parseInt(item, 10);
            });
            var unavailableDeliveryWeekdays = convertWeekdays(availableDeliveryWeekdays);
            // console.log('🏵🏵🏵unavailableDeliveryWeekdays', unavailableDeliveryWeekdays);
            modal.find('input[name="eta"]').datepicker(
                'setDaysOfWeekDisabled', unavailableDeliveryWeekdays,
                'setStartDate', moment().format('YYYY-MM-DD')
            );

            var courierOptions = modal.find('select[name="courier"]')[0].selectize.options;
            // console.log('courierOptions', courierOptions);

            // Update the Title and buttons to refer to editing the order
            switch(operate) {
                case 'assign':
                    modal.find('.block-title').text(modal.find('.block-title').data('assign'));
                    modal.find('#block-description').text(modal.find('#block-description').data('assign'));
                    submitButton.find('span').text(submitButton.find('span').data('assign'));
                break;
                case 'edit':
                    modal.find('.block-title').text(modal.find('.block-title').data('edit'));
                    modal.find('#block-description').text(modal.find('#block-description').data('edit'));
                    submitButton.find('span').text(submitButton.find('span').data('edit'));
                break;
                case 'reschedule':
                    modal.find('.block-title').text(modal.find('.block-title').data('reschedule'));
                    modal.find('#block-description').text(modal.find('#block-description').data('reschedule'));
                    submitButton.find('span').text(submitButton.find('span').data('reschedule'));
                break;
            }

            $.ajax({
                url: button.data('source'),
                type: 'GET',
                error: function(err) {
                    console.warn('Failed to find the order', err);
                },
                success: function(data) {
                    var order = data.data;
                    console.log('selected order is', order);
                    var promises = [];

                    // Set the ETA
                    var eta = moment.utc(order.eta.date).local().format('YYYY-MM-DD');
                    if (operate === 'reschedule') {
                        eta = moment().local().format('YYYY-MM-DD');
                    }

                    // console.log('eta is', eta);
                    // If ETA is in the past, we need to allow the datepicker to include it
                    if (moment(eta).isBefore(moment())) {
                        modal.find('input[name="eta"]').datepicker('setStartDate', eta).datepicker('update', eta);
                    } else{
                        modal.find('input[name="eta"]').datepicker('update', eta);
                    }
                    var loadAddressPromise = $.Deferred();
                    promises.push(loadAddressPromise);

                    // Get the Courier
                    var courier = order.courier;
                    if (courier.data.id) {
                        modal.find('select[name="courier"]')[0].selectize.setValue(courier.data.id);
                    } else {
                        // pre-fill courier if there's only one option
                        // console.log('no courier has been assigned');
                        if (Object.keys(courierOptions).length === 1) {
                            console.log('auto fill courier since there is only one option');
                            modal.find('select[name="courier"]')[0].selectize.setValue(Object.keys(courierOptions)[0]);
                        }
                    }
                    // console.log('courier is', courier.data.id);

                    addressSelectize.load(function(callback) {
                        var self = this;
                        self.disable();
                        $.ajax({
                            url: 'distributor/user-address-search/' + encodeURIComponent(order.user.data.id),
                            type: 'GET',
                            data: {
                                forOrder: order.id
                            },
                            error: function(error) {
                                console.warn('Error getting patient address from patient list change', error);
                                loadAddressPromise.resolve('false');
                                callback();
                            },
                            success: function(result) {
                                self.clearOptions();
                                self.enable();
                                callback(result);
                                // console.log('address result', result, '\n\ndelivery address', order.deliveryAddress);
                                self.setValue(order.deliveryAddress.data.id);
                                loadAddressPromise.resolve('true');
                                modal.find('#distributorOperateOrderBlock').removeClass('block-opt-refresh');
                            }
                        });
                    });

                    // Pass order id from hidden input
                    modal.find('#distributor-operate-order-id').val(order.id);
                    modal.find('#distributor-operate-kind').val(operate);
                    // Unbind submit action on removal
                    modal.on('hide.bs.modal', function (e) {
                        modal.find('#submit-button-reschedule-order').unbind();
                    });
                }
            });
        });
    };

    return {
        init: function($func) {
            switch ($func) {
                case 'uiInit':
                    uiInit();
                    break;
                case 'uiLayout':
                    uiLayout();
                    break;
                case 'uiNav':
                    uiNav();
                    break;
                case 'uiBlocks':
                    uiBlocks();
                    break;
                case 'uiForms':
                    uiForms();
                    break;
                case 'uiHandleTheme':
                    uiHandleTheme();
                    break;
                case 'uiToggleClass':
                    uiToggleClass();
                    break;
                case 'uiScrollTo':
                    uiScrollTo();
                    break;
                case 'uiYearCopy':
                    uiYearCopy();
                    break;
                case 'uiLoader':
                    uiLoader('hide');
                    break;
                default:
                    // Init all vital functions
                    uiInit();
                    uiLayout();
                    uiNav();
                    uiBlocks();
                    uiForms();
                    uiHandleTheme();
                    uiToggleClass();
                    uiScrollTo();
                    uiYearCopy();
                    uiLoader('hide');
            }
        },
        layout: function($mode) {
            uiLayoutApi($mode);
        },
        loader: function($mode) {
            uiLoader($mode);
        },
        blocks: function($block, $mode) {
            uiBlocksApi($block, $mode);
        },
        initHelper: function($helper) {
            switch ($helper) {
                case 'print-page':
                    uiHelperPrint();
                    break;
                case 'table-tools':
                    uiHelperTableToolsSections();
                    uiHelperTableToolsCheckable();
                    break;
                case 'appear':
                    uiHelperAppear();
                    break;
                case 'appear-countTo':
                    uiHelperAppearCountTo();
                    break;
                case 'slimscroll':
                    uiHelperSlimscroll();
                    break;
                case 'magnific-popup':
                    uiHelperMagnific();
                    break;
                case 'ckeditor':
                    uiHelperCkeditor();
                    break;
                case 'summernote':
                    uiHelperSummernote();
                    break;
                case 'slick':
                    uiHelperSlick();
                    break;
                case 'datepicker':
                    uiHelperDatepicker();
                    break;
                case 'colorpicker':
                    uiHelperColorpicker();
                    break;
                case 'tags-inputs':
                    uiHelperTagsInputs();
                    break;
                case 'masked-inputs':
                    uiHelperMaskedInputs();
                    break;
                case 'select2':
                    uiHelperSelect2();
                    break;
                case 'selectize':
                    uiHelperSelectize();
                    break;
                case 'highlightjs':
                    uiHelperHighlightjs();
                    break;
                case 'notify':
                    uiHelperNotify();
                    break;
                case 'draggable-items':
                    uiHelperDraggableItems();
                    break;
                case 'easy-pie-chart':
                    uiHelperEasyPieChart();
                    break;
                case 'maxlength':
                    uiHelperMaxlength();
                    break;
                case 'datetimepicker':
                    uiHelperDatetimepicker();
                    break;
                case 'rangeslider':
                    uiHelperRangeslider();
                    break;
                case 'bootstrap-calendar':
                    uiHelperBootstrapCalendar();
                    break;
                case 'appear-scrollCalendar':
                    uiHelperAppearScrollCalendar();
                    break;
                case 'jstimezonedetect':
                    uiHelperTimezoneDetect();
                    break;
                case 'dataTableSearchToggle':
                    uiHelperDataTableSearchToggle();
                    break;
                case 'editable':
                    uiHelperEditable();
                    break;
                case 'patientDeletion':
                    uiHelperPatientDeletion();
                    break;
                case 'nhiForm':
                    uiHelperNHIForm();
                    break;
                case 'mohForm':
                    uiHelperMOHForm();
                    break;
                case 'productUsageReport':
                    uiHelperProductUsageReport();
                    break;
                case 'bleedingReport':
                    uiHelperBleedingReport();
                    break;
                case 'bleedsTreatmentReport':
                    uiHelperBleedsTreatmentReport();
                    break;
                case 'stockUsageReport':
                    uiHelperStockUsageReport();
                    break;
                case 'htcReport':
                    uiHelperHTCReport();
                    break;
                case 'conditionize':
                    uiHelperConditionize();
                    break;
                case 'infusionSchedule':
                    uiHelperInfusionSchedule();
                    break;
                case 'markAlertsAsRead':
                    uiHelperMarkAlertsAsRead();
                    break;
                case 'orderManageInventory':
                    uiHelperOrderManageInventory();
                    break;
                case 'orderManageOrders':
                    uiHelperOrderManageOrders();
                    break;
                case 'orderHistory':
                    uiHelperOrderHistory();
                    break;
                case 'pharmacistManageInventory':
                    uiHelperPharmacistManageInventory();
                    break;
                case 'pharmacistManageOrder':
                    uiHelperPharmacistManageOrder();
                    break;
                case 'pharmacistPatientManagement':
                    uiPharmacistPatientManagement();
                    break;
                case 'distributorManageOrder':
                    uiHelperDistributorManageOrder();
                    break;
                case 'orderStockManagement':
                    uiHelperOrderStockManagement();
                    break;
                case 'orderManageDeliveries':
                    uiHelperOrderManageDeliveries();
                    break;
                case 'orderCoordinatorDashboard':
                    uiHelperCoordinatorDashboard();
                    break;
                default:
                    return false;
            }
        },
        initHelpers: function($helpers) {
            if ($helpers instanceof Array) {
                for(var $index in $helpers) {
                    App.initHelper($helpers[$index]);
                }
            } else {
                App.initHelper($helpers);
            }
        }
    };
}();

// Create an alias for App (you can use OneUI in your pages instead of App if you like)
var OneUI = App;

// Initialize app when page loads
jQuery(function(){
    if (typeof angular == 'undefined') {
        App.init();
    }
});